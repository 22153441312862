import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  AfterContentInit
} from '@angular/core';
import {
  UserProfile, UserProfileService
} from '../../../../services/user-profile/user-profile.service';
import { DEFAULT_MATTER, Matter } from '../../../../services/matters/matters.service';
import {
  UtilitiesService
} from '../../../../services/utilities.service';
import { LawFirm, LIST_NAME_LAW_FIRMS } from '../../../../services/lists/lists.service';
import { ClientContact } from '../../../../services/client-contacts/client-contacts.service';
import { AttorneysService } from '../../../../services/attorneys/attorneys.service';
import { Plaintiff } from '../../../../services/plaintiffs/plaintiffs.service';
import { Defendant } from '../../../../services/defendants/defendants.service';
import { SnackbarsService } from '../../../../services/messaging/snackbars/snackbars.service';
import { UsercurrencyPipe } from '../../../../pipe/usercurrency.pipe';
import { UsercurrencyDirective } from '../../../../directive/usercurrency.directive';
import { OtherClients } from '../../../../services/other-clients/other-clients.service';
import { Router } from '@angular/router';
import { PATH_DASHBOARD } from '../../../../services/appdata/app-config.service';
import * as moment from 'moment';
import { IndexedDBService } from '../../../../services/indexed-db/indexed-db.service';

@Component({
  selector: 'app-matter-details',
  templateUrl: './matter-details.component.html',
  styleUrls: ['./matter-details.component.scss']
})
export class MatterDetailsComponent implements OnInit, OnChanges, AfterViewInit {

  bsValue = new Date();

  showMatterExistsPrompt: boolean;
  onShowFirmPaymentProfile: boolean;

  @Input() isLoading: boolean;
  @Input() workingMatter: Matter;
  @Input() matterPlaintiffs: string;
  @Input() matterDefendants: string;
  @Input() lawFirms: LawFirm[];
  @Input() attorneys: ClientContact[];
  @Input() plaintiffs: Plaintiff[];
  @Input() otherClients: OtherClients[];
  @Input() defendants: Defendant[];
  @Input() attorneyRefs: string[];
  @Input() instructions: string[];
  @Input() matter: Matter;
  @Input() matters: Matter[];
  @Input() _matters: Matter[];
  @Input() userProfile: UserProfile;
  @Output() workingMatterChange: EventEmitter<Matter> = new EventEmitter<Matter>();
  @Output() matterPlaintiffsChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() matterOtherClientsChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() matterDefendantsChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() matterLawfirmChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() matterAttorneyChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() matterDetailsChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() addPlaintiff: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('paymentTermsRef') paymentTermsRef: ElementRef;
  @ViewChild('inputHourly') focusHourly: ElementRef;
  @ViewChild('inputDaily') focusDaily: ElementRef;
  @ViewChild('inputPerKilometer') focusPerKilometer: ElementRef;

  title = '';

  profileTypes = {
    ADVOCATE: 'advocate',
    ASSISTANT: 'assistant',
    ATTORNEY: 'Attorney',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  plaintiff: string;
  defendant: string;
  avaragePaymentRating: number;
  dropdowns: any = {
    showLawFirms: false,
    showAttorneys: false,
    showPlaintiffs: false,
    showDefendants: false,
    showAttorneyRefferences: false,
    showInstructions: false
  };

  searchlist: string[];
  lawFirmNames: string[] = [];
  isLawfirmRatingLoading: boolean;
  lawfirmRatingLabel: string;

  lawfirmRatingsData: any;

  // Payment Terms
  paymentTerms: any;
  trialDateType: any;

  get currencySymbol(): string {
    if (this.userProfileService.userProfile.isImpersonator) {
      return this.userProfileService.selectedUserProfile.currencyDetails.symbol + ' ';
    } else {
      return this.userProfileService.userProfile.currencyDetails.symbol + ' ';
    }
  } // end currencySymbol()

  // Is Internal Attorney
  get isAttorney(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.ATTORNEY);
  } // end isAttorney()

  // Is Internal isAttorneyAssistant
  get isAttorneyAssistant(): boolean {
    return Boolean(this.userProfileService.userProfile.isImpersonator) &&
    Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.ATTORNEY);
  } // end isAttorneyAssistant()

  // Is Internal isLawfirmAssistant
  get isLawfirmAssistant(): boolean {
    return Boolean(this.userProfileService.userProfile.isImpersonator) &&
    Boolean(this.userProfileService.selectedUserProfile.profileType === this.profileTypes.LAWFIRM);
  } // end isLawfirmAssistant()

  // Is Internal Lawfirm
  get isLawfirm(): boolean {
    return Boolean(this.userProfileService.userProfile.profileType === this.profileTypes.LAWFIRM);
  } // end isLawfirm()

  get isTrialDateTextAdvocate(): boolean {
    return Boolean(this.userProfile && this.userProfile.serviceProviderID === 28678 
      || this.userProfileService.selectedUserProfile && this.userProfileService.selectedUserProfile.serviceProviderID === 28678);
  } // end isTrialDateTextAdvocate()

  get isDirty(): boolean {
    this.workingMatter.HourlyRate = Number.parseFloat(this.workingMatter.HourlyRate.toString());
    this.workingMatter.DailyRate = Number.parseFloat(this.workingMatter.DailyRate.toString());
    this.workingMatter.PerKilometerRate = Number.parseFloat(this.workingMatter.PerKilometerRate.toString());
    // return !this.util.objectIsSame(this.workingMatter, this.matter);
    return false;
  } // end get isDirty()

  get isLawfirmSelected(): boolean {
    if (this.workingMatter) {
      return !Boolean(this.workingMatter.ContactID > 0);
    } else {
      return true;
    }
  } // end get isLawfirmSelected()

  get isRafMatter(): boolean {
    const raf = 'RAF';
    const roadAccidentFund = 'Road Accident Fund';
    const theRoadAccidentFund = 'The Road Accident Fund';
    const padOngeluksFonds = 'Pad Ongeluks Fonds';
    const pof = 'POF';
    return Boolean(this.workingMatter.DefendantFullName.match(new RegExp(raf, 'gi'))) ||
      Boolean(this.workingMatter.DefendantFullName.match(new RegExp(roadAccidentFund, 'gi'))) ||
      Boolean(this.workingMatter.DefendantFullName.match(new RegExp(theRoadAccidentFund, 'gi'))) ||
      Boolean(this.workingMatter.DefendantFullName.match(new RegExp(padOngeluksFonds, 'gi'))) ||
      Boolean(this.workingMatter.DefendantFullName.match(new RegExp(pof, 'gi')));
  } // end isRafMatter()

  get isTheStateAttorney(): boolean {
    const state = '^The State Attorney'; // '^' is used to match the start of the string
    return new RegExp(state, 'i').test(this.workingMatter.InternalRefNo);
  } // end isTheStateAttorney()

  images: any;

  constructor(
    private router: Router,
    private util: UtilitiesService,
    private cpipe: UsercurrencyPipe,
    private snackbarsService: SnackbarsService,
    private indexedDbService: IndexedDBService,
    private attorneysService: AttorneysService,
    private userProfileService: UserProfileService,
    private currencyDirective: UsercurrencyDirective
  ) {
    this.isLoading = true;
    this.images = {
      spinner: {
        path: '../../../../../assets/images/logos/spinner.gif',
        label: 'Auxcon spinner'
      } // spinner{}
    };
  } // end constructor()

  ngOnInit() {
    this.paymentTerms = [
      { id: 0, name: '0 Days' },
      { id: 7, name: '7 Days' },
      { id: 30, name: '30 Days' },
      { id: 60, name: '60 Days' },
      { id: 90, name: '90 Days' },
      { id: 97, name: '97 Days' },
      { id: 98, name: '98 Days' },
      { id: 99, name: 'Contingency'}
    ];

    this.trialDateType = [
      { label: 'DATE', value: 'DATE' },
      { label: 'SETTLEMENT DATE', value: 'SETTLEMENT DATE' },
      { label: 'TIC', value: 'TIC' },
      { label: 'TRIAL DATE', value: 'TRIAL DATE' },
      { label: 'SIC DATE', value: 'SIC DATE' },
      { label: 'DEFAULT JUDGMENT DATE', value: 'DEFAULT JUDGMENT DATE' }
    ]

    if (this.userProfile && this.userProfile.serviceProviderID === 13553 
      || this.userProfileService.selectedUserProfile && this.userProfileService.selectedUserProfile.serviceProviderID === 13553) {
      this.paymentTerms.push(
        { id: 7, name: '7 Days' }
      )
      this.paymentTerms.sort((a, b) => {
        return a.id - b.id;
      })
    }

    if (!this.lawFirms) {
      // if (localStorage.getItem(LIST_NAME_LAW_FIRMS)) {
      //   this.lawFirms = JSON.parse(localStorage.getItem(LIST_NAME_LAW_FIRMS));
      // }
      this.indexedDbService.getData(LIST_NAME_LAW_FIRMS, 'lawFirmsData').then((data) => {
        if (data) {
            this.lawFirms = data.data; // Assuming your data is stored under the 'data' key
        } else {
            this.lawFirms = []; // Or any default value you wish to set
        }
    }).catch((error) => {
        console.error('Error retrieving law firms from IndexedDB', error);
        this.lawFirms = []; // Setting a default value in case of an error
    });
    }

    if (this.workingMatter && this.workingMatter.ServiceID) {
      this.bsValue = moment(this.matter.ServiceDate).toDate();
      // this.attorneys =  this.attorneysService.getAttorneysByLawFirms(this.workingMatter.ContactID).toPromise();
    } else {
      this.workingMatter.ServiceDate = moment(this.bsValue).format('YYYY-MM-DDTHH:mm:ss.SSS');
    }
    this.isLawfirmRatingLoading = false;
  } // end ngOnInit()

  ngAfterViewInit() {
    if (this.workingMatter.ContactID && this.userProfile.profileType !== this.profileTypes.ATTORNEY
      && this.userProfile.profileType !== this.profileTypes.LAWFIRM) {
      this.attorneysService.getLawfirmRating(this.workingMatter.ContactID).subscribe({ next:
        (avarage_rating) => {
          if (avarage_rating) {
            this.avaragePaymentRating = avarage_rating.AverageDaysToPay;
            if (avarage_rating.AverageDaysToPayment === 'Not Applicable') {
              this.lawfirmRatingLabel = 'No data available.';
            } else {
              this.lawfirmRatingLabel = 'On avarage pay within ' + this.avaragePaymentRating + ' days';
            }
          } else {
            this.lawfirmRatingLabel = 'No data available.';
          }
        },
        error: (error) => {
          // On error
          this.lawfirmRatingLabel = 'No data available.';
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.workingMatter.ServiceID > 0) {
      this.title = this.workingMatter.Description;
    } else {
      this.title = 'new matter';
    }
    if (changes.lawFirms && changes.lawFirms.currentValue) {
      this.isLoading = false;
    }

    if (changes.matter && changes.matter.currentValue) {
       //this.workingMatter = this.util.objectCopy(this.matter);
    }
    this.ngAfterViewInit();
  } // end ngOnChanges()

  onReferenceChangeCheck() {
    if (this.workingMatter.ServiceID === 0) {
      const ref_matter = this._matters.filter(a => a.ClientRefNo === this.workingMatter.ClientRefNo);
      if (ref_matter.length > 0) {
        this.showMatterExistsPrompt = true;
      }
    }
  } // end onReferenceChangeCheck()

  navigateToDashboard() {
    this.workingMatter = Object.assign({}, this.matter)
    this.workingMatterChange.emit(this.workingMatter);
    this.router.navigate([PATH_DASHBOARD]);
    this.onHideMatterExistsPrompt();
  } // end navigateToDashboard()

  onHideMatterExistsPrompt() {
    this.showMatterExistsPrompt = false;
  } // end onHideMatterExistsPrompt()

  onHourlyRateChange() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.focusHourly.nativeElement.value =
      this.cpipe.transform(this.focusHourly.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
    } else {
        this.focusHourly.nativeElement.value =
        this.cpipe.transform(this.focusHourly.nativeElement.value,
          this.userProfileService.userProfile.currencyDetails.symbol, 2);
    }
  } // end onHourlyRateChange()
  onHourlyRateClicked() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.focusHourly.nativeElement.value =
      this.cpipe.parse(this.focusHourly.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
    } else {
      this.focusHourly.nativeElement.value =
      this.cpipe.parse(this.focusHourly.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
    }
  } // end onHourlyRateClicked()
  onDailyRateChange() {
    if (!this.focusDaily.nativeElement.value.startsWith('R')) {
      if (this.userProfileService.userProfile.isImpersonator) {
        this.focusDaily.nativeElement.value =
        this.cpipe.transform(this.focusDaily.nativeElement.value,
          this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
      } else {
        this.focusDaily.nativeElement.value =
        this.cpipe.transform(this.focusDaily.nativeElement.value,
          this.userProfileService.userProfile.currencyDetails.symbol, 2);
      }
    }
  } // end onDailyRateChange()
  onDailyRateClicked() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.focusDaily.nativeElement.value =
      this.cpipe.parse(this.focusDaily.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
      } else {
      this.focusDaily.nativeElement.value =
      this.cpipe.parse(this.focusDaily.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
      }
  } // end onDailyRateClicked()
  onPerkilometerRateChange() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.focusPerKilometer.nativeElement.value =
      this.cpipe.transform(this.focusPerKilometer.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol, 2);
      } else {
      this.focusPerKilometer.nativeElement.value =
      this.cpipe.transform(this.focusPerKilometer.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol, 2);
      }
  } // end onPerkilometerRateConPerkilometerRateClickedhange()
  onPerkilometerRateClicked() {
    if (this.userProfileService.userProfile.isImpersonator) {
      this.focusPerKilometer.nativeElement.value =
      this.cpipe.parse(this.focusPerKilometer.nativeElement.value,
        this.userProfileService.selectedUserProfile.currencyDetails.symbol);
      } else {
      this.focusPerKilometer.nativeElement.value =
      this.cpipe.parse(this.focusPerKilometer.nativeElement.value,
        this.userProfileService.userProfile.currencyDetails.symbol);
      }
  } // end onPerkilometerRateClicked()

  /**
   * Transforms serviceDate string into a format that will work with
   * input elements of type 'date'.
   * @param {string} serviceDate The date the matter was created.
   * @return {string} Returns an HTML friendly date string for use in input
   * elements of type 'date'.
   */
  parseServiceDate(serviceDate: string): Date {
    if (serviceDate) {
      return new Date(serviceDate);
    } else {
      return null;
    }
  } // end parseServiceDate()

  /**
   * Called when the date value has changed.
   * @param {Date} value The new date value.
   */
  onDateValueChange(value: Date): void {
    this.bsValue = value;
    this.workingMatter.ServiceDate = moment(value).format('YYYY-MM-DDTHH:mm:ss.SSS');
  } // end onDateValueChange()

  onAppearanceDateChange(value: Date): void {
    this.workingMatter.DateOfAppearance = moment(value).format('YYYY-MM-DDTHH:mm:ss.SSS');
  } // end onAppearanceDateChange()

  sortAttorneysList() {
    if (this.attorneys.length > 0) {
      this.attorneys.sort((a, b) => {
        if (a && a.ClientFullName !== undefined && b && b.ClientFullName !== undefined) {
        return a.ContactFullName.toUpperCase().localeCompare(a.ContactFullName.toUpperCase());
        }
      });
    }
  }

  onHideFirmPaymentProfile() {
    this.onShowFirmPaymentProfile = false;
  } // end onHideFirmPaymentProfile()

  /**
   * Called when the model state changes.
   * @param {string} type The type of model property that changed.
   * @param {Contact} contact The new property value for the given type.
   */
  onModelChange(type: string, contact: any) {
    switch (type) {
      case 'law-firms':
        // Re-build attorneys list
        if (contact.ContactID) {
          // this.workingMatter.ContactID = contact.ContactID;
          // this.workingMatter.NewClientDetail = contact.FullName;
          this.isLawfirmRatingLoading = true;
          this.attorneysService.getLawfirmRating(contact.ContactID).subscribe({next: 
            (avarage_rating) => {
              if (avarage_rating) {
                this.lawfirmRatingsData = avarage_rating;
                this.avaragePaymentRating = avarage_rating.AverageDaysToPay;
                if (avarage_rating.AverageDaysToPayment === 'Not Applicable') {
                  this.lawfirmRatingLabel = 'No data available.';
                } else {
                  this.lawfirmRatingLabel = 'On avarage pay within ' + this.avaragePaymentRating +  ' days';
                }
                // this.lawfirmRatingLabel = 'On avarage pay within ' + this.avaragePaymentRating + ' days';
              } else {
                this.lawfirmRatingLabel = 'No data available.';
              }
            },
            error: (error) => {
              // On error
              console.log('ERROR getLawfirmRating: ', error)
              this.isLawfirmRatingLoading = false;
              this.lawfirmRatingLabel = 'No data available.';
            },
            complete: () => {
              // On complete              
              // if (this.lawfirmRatingsData && this.lawfirmRatingsData.WorkedOnFirmPastThreeMonths === false) {
                if (
                  // this.lawfirmRatingsData && this.lawfirmRatingsData.AverageDaysToPay > 150 ||
                  this.lawfirmRatingsData && this.lawfirmRatingsData.InLegal > 0 ||
                  this.lawfirmRatingsData && this.lawfirmRatingsData.LPCComplaints > 0 ||
                  this.lawfirmRatingsData && this.lawfirmRatingsData.GCBListing > 0) {
                    this.onShowFirmPaymentProfile = true;
                  }
              // }
              this.isLawfirmRatingLoading = false;
            }
          });
          this.attorneysService.getAttorneysByLawFirms(contact.ContactID).subscribe({ next:
            (contacts) => {
              this.attorneys = contacts;
              this.sortAttorneysList();
            },
            error: (error) => {
              const msg = 'Error loading Attorney list.';
              console.error(msg, error);

            },
            complete: () => {
              if (contact.FullName) {
                 // Reset attorneys field
                this.workingMatter.ClientContactID = 0;
                this.workingMatter.NewClientDetail = '';
            }
          }
        });
        } else {
          contact.FullName = '';
          contact.ContactID = 0;
        }
        
        if (this.isTheStateAttorney && this.userProfileService.userProfile.isImpersonator) {
          this.workingMatter.MAAA = this.userProfileService.selectedUserProfile.settings.maaa;
        } else if (this.isTheStateAttorney && !this.userProfileService.userProfile.isImpersonator) {
          this.workingMatter.MAAA = this.userProfile.settings.maaa;
        }
        break;

      case 'attorneys':
        //this.workingMatter.NewClientDetail = contact.FullName;
        // this.workingMatter.DefendantFullName = contact.FullName;
        this.workingMatter.ClientContactID = contact.ContactID;
        this.matterAttorneyChange.emit(contact);
        contact.FullName = '';
        contact.ContactID = 0;
        break;

      case 'plaintiffs':
        // if (contact.target.value) {
        //this.workingMatter.PlaintiffFullName = contact.FullName;
        this.workingMatter.PlaintiffContactID = contact.ContactID;
        // }
        this.matterPlaintiffsChange.emit(contact);
        contact.FullName = '';
        contact.ContactID = 0;
        break;
      case 'other-clients':
        this.workingMatter.PlaintiffContactID = contact.ContactID;
        // }
        this.matterOtherClientsChange.emit(contact);
        contact.FullName = '';
        contact.ContactID = 0;
        this.workingMatter.PlaintiffContactID = 0;
        break;

      case 'defendants':
        // if (contact.target.value) {
        //this.workingMatter.DefendantFullName = contact.FullName;
        this.workingMatter.DefendantContactID = contact.ContactID;
        // }
        this.matterDefendantsChange.emit(contact);
        contact.FullName = '';
        contact.ContactID = 0;
        break;

      case 'attorney-reference':
        this.workingMatter.ClientRefNo = contact.FullName;
        contact.FullName = '';
        contact.ContactID = 0;
        break;

      case 'instructions':
        this.workingMatter.Instructions = contact.FullName;
        contact.FullName = '';
        contact.ContactID = 0;
        break;
    }
    //this.workingMatterChange.emit(this.workingMatter);
  } // end onModelChange()

  logServiceDate() {
    // TODO: Implement logServiceDate()
  } // end logServiceDate()

  onCurrencyInputFocus(event) {
    // this.util.formatCurrencyInput(event.target);
  } // end onCurrencyInputFocus()

  onCurrencyInputChange(event) {
    // const num = event.replace(/[R,]/g, '');
    // return Number(num);
    // this.util.formatCurrencyInput(event.target);
  } // end onCurrencyInputChange()
} // end MatterDetailsComponent{}
