<div class="modal" *ngIf="isVisible">
    <div class="modal-content">
        <div class="modal-dialog">

            <!-- Modal Header -->
            <div class="modal-header">
                <h2>{{ title }}</h2>
                <button class="modal-dismiss" (click)="onCancel()">
                    <i class="fa fa-close"></i>
                </button>
            </div>

            <!-- Modal Body -->
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <!-- PDF Viewer -->
                        <div class="col-12">
                            <iframe
                                [src]="pdfSafeUrl"
                                width="100%"
                                height="500px"
                                style="border: none;">
                            </iframe>
                        </div>
                    </div>

                    <!-- Terms Acceptance Checkbox -->
                    <div class="row mt-3">
                        <div class="col-12">
                            <input type="checkbox" id="acceptTerms" [(ngModel)]="isAccepted" />
                            <label for="acceptTerms">&nbsp;&nbsp;I have read and agree to the Terms & Conditions.</label>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal Footer -->
            <div class="modal-footer" style="justify-content: flex-end !important;" >
                <button class="btn btn-success" style="width: 25%;" [disabled]="!isAccepted" (click)="accept()">Accept</button>
            </div>

        </div>
    </div>
</div>
