import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import {
  HttpClient,
  HttpHeaders,
  HttpBackend,
  HttpErrorResponse,
} from "@angular/common/http";
import {
  ApiService
} from '../api.service';
import { ToastService, Toast } from '../messaging/toast/toast.service';
import { catchError, retryWhen, retry } from 'rxjs/operators';
import {
  RATE_TYPE_PER_KILOMETER,
  RATE_TYPE_HOURLY,
  RATE_TYPE_ONCE_OFF,
  RATE_TYPE_DISBURSEMENT,
  RATE_TYPE_NON_BILLABLE,
  RATE_TYPE_PER_PAGE,
  RATE_TYPE_DAILY,
} from "../fee-items/fee-items.service";

export interface UserPermissions {
  view_Dashboard: boolean;
  view_Brief: boolean;
  edit_Brief: boolean;
  view_Service: boolean;
  create_Service: boolean;
  edit_Service: boolean;
  create_Fee: boolean;
  edit_Fee: boolean;
  delete_Fee: boolean;
  send_Invoice_With_Approval: boolean;
  send_Invoice_Without_Approval: boolean;
  edit_Invoice_Recipients: boolean;
  resend_Invoice: boolean;
  create_Credit_Note: boolean;
  process_Reconciliation: boolean;
  view_Report_Statement: boolean;
  view_Report_Aging: boolean;
  view_Report_Financials: boolean;
} // end UserPermissions{}

export interface CheckSageUserValid {
  SageUserName: string;
  Password: string;
}

export interface AdvocateSageUserpup {
  SageUserName: string;
  Password: string;
  CompanyId: string;
}
export interface UserSettings {
  show_Dashboard: boolean;
  aging: string;
  arfra: boolean;
  signedDate: string;
  practiceType: string;
  involvementInPractice: string;
  SubscriptionRenewal: string;
  SubscriptionTerm: string;
  SubscriptionIncrease: string;
  DebitOrder: boolean;
  AuxconBaseSystem: boolean;
  AuxconInvoicing: boolean;
  AuxconReconcilliations: boolean;
  AuxconCollections: boolean;
  PremiumInvoicing: boolean;
  PremiumCollections: boolean;
  bccEmail: string;
  bcc: boolean;
  maaa: string;
  manualTransaction: boolean;
  isHistoricalImported: boolean;
  termsAccepted: boolean;
}

export interface InviteExternalUser {
  AccountType: string
  Domain: string
  EmailAddress: string
  EntityReference: string
  EntityType: string
  Id: string
  IsClosed: boolean
  LastModifiedOn: string
  Status: string
  StatusError: string
  TenantID: string
  UserFirstName: string
  UserLastName: string
  UserType: string
}

export const DEFAULT_EXTERNAL_USER: InviteExternalUser = {
  AccountType: '',
  Domain: '',
  EmailAddress: '',
  EntityReference: '',
  EntityType: '',
  Id: '',
  IsClosed: false,
  LastModifiedOn: '',
  Status: '',
  StatusError: '',
  TenantID: '',
  UserFirstName: '',
  UserLastName: '',
  UserType: ''
}; // end MatterQuotationDTO()

export interface ContactsExtension {
  id: number;
  contactId: number;
  personalityType: string;
  relationshipStatus: string;
  partnerName: string;
  numberOfDependants: number;
  meetingType: string;
  meetingAppName: string;
  meetingFrequency: string;
  statementRunDate: string;
  preAdvocacy: string;
  preAdvocacyDetails: string;
  practiceManagerAccess: boolean;
  admissionDate: string;
  admissionDay: number;
  admissionMonth: number;
  admissionYear: number;
  statementReminderDay: number;
  feesLastUpdate: string;
}

export interface UserDetails {
  title: string;
  fullName: string;
  firstName: string;
  lastName: string;
  idNumber: string;
  speciality: string;
  professionalLevel: string;
  vatNumber: string;
  taxValue: number;
  lastVATPeriodEndDate: string;
  vatBasedOn: string;
  gender: string;
  ethincity: string;
} // end UserDetails{}

export interface AddNewUser {
  AuxconUserName: string;
  JobTitle: string;
  FirstName: string;
  LastName: string;
  EmailAddress: string;
  MobileNumber: string;
  ServiceProviderId: number;
  AddFeeTemplates: boolean;
}

export interface UserOnboardingMinimum {
  PersonalDetailsOnboard: PersonalDetailsOnboard;
  ContactDetailsMinimum: ContactDetailsMinimum;
  OptionalProcessing: OptionalProcessing;
}

export interface PersonalDetailsOnboard {
  JobTitle: string;
  FirstName: string;
  LastName: string;
  ServiceProviderID: number;
  AuxconUserName: string;
}

export interface ContactDetailsMinimum {
  EmailAddress: string;
  MobileNumber: string;
}

export interface OptionalProcessing {
  AddTemplates: Boolean;
}


export interface ContactDetails {
  emailAddress: string;
  mobileNumber: string;
  workPhone: string;
  workFax: string;
  streetAddressFull: string;
  streetAddressLine1: string;
  streetAddressLine2: string;
  streetAddressSuburb: string;
  streetAddressCity: string;
  streetAddressProvince: string;
  streetAddressCode: string;
  streetAddressCountry: string;
  postalAddressFull: string;
  postalAddressLine1: string;
  postalAddressLine2: string;
  postalAddressSuburb: string;
  postalAddressCity: string;
  postalAddressProvince: string;
  postalAddressCode: string;
  postalAddressCountry: string;
  docex: string;
  localityID: number;
  website: string;
} // end ContactDetails{}

export interface BankDetails {
  bankID: number;
  branchCode: string;
  accountNumber: string;
  accountType: string;
  accountHolder: string;
} // end BankDetails{}

export interface BillingDetails {
  defaultHourlyRate: number;
  defaultDailyRate: number;
  defaultPerKilometerRate: number;
  feeTarget: number;
  incomeTarget: number;
  collectThreshold: number;
  toCollect: boolean;
  startingInvoiceNumber: number | string;
  feesLastUpdate: string;
} // end BillingDetails{}

export interface Licence {
  id: string;
  type: number;
  description: string;
  expiryDate: string;
} // end Licence{}

export interface CurrencyDetails {
  name: string;
  description: string;
  code: string;
  symbol: string;
  culture: string;
}

export interface UserProfile {
  adUserName: string;
  auxconUserName: string;
  serviceProviderID: number;
  licenses: Licence[];
  permissions: UserPermissions;
  settings: UserSettings;
  personalDetails: UserDetails;
  contactDetails: ContactDetails;
  bankDetails: BankDetails;
  billingDetails: BillingDetails;
  currencyDetails: CurrencyDetails;
  isImpersonator: boolean;
  impersonates: UserProfile[];
  contactsExtension: ContactsExtension;
  // addresses: Addresses;
  profileType: string;
  regionalBarId: number;
  regionalBarName: string;
  accountingPlatform: string;
  contactSubscriptions: ContactSubscriptions;
} // end UserProfile{}

export interface AddNewUser {
  AuxconUserName: string;
  JobTitle: string;
  FirstName: string;
  LastName: string;
  EmailAddress: string;
  MobileNumber: string;
  ServiceProviderId: number;
  AddFeeTemplates: boolean;
}

export interface UserOnboardingMinimum {
  isAlreadyOfficeUser: boolean
  PersonalDetailsOnboard: PersonalDetailsOnboard;
  ContactDetailsMinimum: ContactDetailsMinimum;
  OptionalProcessing: OptionalProcessing;
}

export interface PersonalDetailsOnboard {
  JobTitle: string;
  FirstName: string;
  LastName: string;
  ServiceProviderID: number;
  AuxconUserName: string;
}

export interface ContactDetailsMinimum {
  EmailAddress: string;
  MobileNumber: string;
}

export interface OptionalProcessing {
  AddTemplates: Boolean;
}

export interface ContactSubscriptions {
  contactID: number;
  subscriptionPlanID: number;
  freeTrialDays: number;
  startDate: string;
  nextBillingDate: string;
  status: string;
}

export interface Addresses {
  AddressID: number;
  ContactID: number;
  Street1: string;
  Street2: string;
  Suburb: string;
  City: string;
  StateProvince: string;
  PostalCode: string;
  AddressType: string;
  Country: string;
}

@Injectable()
export class UserProfileService {
  userProfile: UserProfile;
  selectedUserProfile: UserProfile;
  isInDashboardScreen = false;
  isJustSignedIn = false;
  isJustSignedInNav = false;
  isInAttorneyDashboardScreen = false;
  isInPMDashboardScreen = false;
  refreshSelectedProfile = false;
  currentOrRequestedTab: string;
  private httpClient: HttpClient;
  contactSubscriptions: ContactSubscriptions;
  private subscription = new BehaviorSubject<ContactSubscriptions>({} as any);

  adHocInstruction = false;

  constructor(
    private http: HttpClient,
    private api: ApiService,
    private handler: HttpBackend,
    private toastService: ToastService
  ) {
    this.httpClient = new HttpClient(handler)
    if (sessionStorage) {
      const userProfile: UserProfile = JSON.parse(
        sessionStorage.getItem("userProfile")
      );
      const selectedUserProfile: UserProfile = JSON.parse(
        sessionStorage.getItem("advocateUserProfil")
      );
      if (userProfile) {
        this.userProfile = userProfile;

        if (userProfile.isImpersonator === true && selectedUserProfile) {
          if (selectedUserProfile) {
            if (selectedUserProfile.serviceProviderID) {
              this.api.serviceProviderID =
                selectedUserProfile.serviceProviderID;
              this.selectedUserProfile = selectedUserProfile;
            } else {
              this.selectedUserProfile = selectedUserProfile[0];
              this.api.serviceProviderID =
                this.selectedUserProfile.serviceProviderID;
            }
          }
        } else {
          this.api.serviceProviderID = userProfile.serviceProviderID;
        }
      }
    }
  } // end constructor()

  /**
   * GET: returns a detialed User Profile including:
   * - High level identification data
   * - License related data
   * - Personal, contact and banking data
   * - Billing config data
   * @param {number} serviceProviderID The Unique Identifier of the Service Provider
   */
  setContactSubscription(contactSubscriptions: ContactSubscriptions) {
    this.subscription.next(contactSubscriptions);
  }

  getContactSubscription() {
    return this.subscription.asObservable();
  }

  getUserProfile(): Observable<any> {
    return this.http.get(this.api.endpoints.userProfile +
      '?ServiceProviderID=' + 0, this.api.httpOptions).pipe(
        catchError(this.api.handleError)
      );
  } // end getUserProfile()

  searchAddress(place: string): Observable<any> {
    return this.http.get(this.api.endpoints.googlePlaces +
      '/autocomplete?input=' + place, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end searchAddress()

  searchPlacesDetail(placeId: string): Observable<any> {
    return this.http.get(this.api.endpoints.googlePlaces +
      '/details?placeId=' + placeId, this.api.httpOptions)
      .pipe(
        catchError(this.api.handleError)
    );
  } // end searchPlacesDetail()


  validateSingupUser(guid: string): Observable<any> {
    return this.httpClient.post<any>(this.api.endpoints.singupUser +
      '?guid=' + guid, this.api.httpOptions).pipe(
        catchError(this.api.handleError)
      );
  }

  getOffice365UserPendingInfo(authCode: string, guid: string): Observable<any> {
    return this.httpClient.post<any>(this.api.endpoints.GetOfficeUserDetails +
      '?guid=' + guid + '&authCode=' + authCode, this.api.httpOptions).pipe(
        catchError(this.api.handleError)
      );
  }


  /**
   * PUT: updates certain on-security related User Profile data including:
   * - Personal, contact and banking data
   * - Billing config data
   * @param {any} userProfile Identifier of Service Provider
   */
  updateUserProfile(userProfile: UserProfile): Observable<any> {
    return this.http
      .put(
        this.api.endpoints.userProfile + "/" + this.api.serviceProviderID,
        userProfile,
        this.api.httpOptions
      )
      .pipe(catchError(this.api.handleError));
  } // end updateUserProfile()


  createNewUser(newUserProfile: UserOnboardingMinimum, inviteId: string): Observable<UserOnboardingMinimum> {
    const UserOnboardingMinimum = {
      isAlreadyOfficeUser: newUserProfile.isAlreadyOfficeUser,
      PersonalDetailsOnboard: newUserProfile.PersonalDetailsOnboard,
      ContactDetailsMinimum: newUserProfile.ContactDetailsMinimum,
      OptionalProcessing: newUserProfile.OptionalProcessing,
    };
    return this.httpClient.post<UserOnboardingMinimum>
      (
        this.api.endpoints.onboarding + '?inviteId=' + inviteId, UserOnboardingMinimum
        , this.api.httpOptions
      )
      .pipe(retry(0), catchError(this.api.handleError));
  }
} // end UserProfileService{}
