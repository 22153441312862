<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet">
<div class="row" style="background-color: #fff;" *ngIf="!hidden && isShowFreeTrial">
  <div class="col-md-9">
    <div class="progress" style="border-radius: 10px;">
      <div class="progress-bar" role="progressbar"
        [ngStyle]="{ 'width': TrialPercentage + '%', 'background-color': '#DBBB2D' }">
      </div>
    </div>
  </div>
  <div class="col-md-3" style="top: -3px;">
    {{ TrialDays }} days of your {{ FreeTrialDays }} days FREE TRIAL remaining
  </div>
</div>
<div class="row" *ngIf="!hidden && isShowFreeTrialNotification">
  <div class="col-md-12 text-center">
    <div class="alertMessage">
      Your subscription is expired on {{ NextBillingDate }}
    </div>
  </div>
</div>
<nav class="navbar" *ngIf="!hidden" (NavigationEnd)="onNavigationEnd($event)">
  <a class="navbar-brand" *ngIf="isPracticeManager" routerLink={{links.practiceMDashboard}}>
    <img src={{images.logo.path}} alt={{images.logo.description}}>
  </a>
  <a class="navbar-brand" *ngIf="!isExternalLawfirm && !isExternalAttorney && !isPracticeManager"
    routerLink={{links.dashboard}}>
    <img src={{images.logo.path}} alt={{images.logo.description}}>
  </a>
  <a class="navbar-brand" *ngIf="(isExternalLawfirm || isExternalAttorney) && !isPracticeManager"
    (click)="updateActiveTab(links.lawfirm_dashboard)">
    <img src={{images.logo.path}} alt={{images.logo.description}}>
  </a>
  <ul class="navbar-nav">
    <li class="nav-item">
      <span class="app-login-menu-link act-on-behalf" id="onBehalf_span" (click)="switchAdvocates()"
        title="You have to be on the dashboard screen to be able to switch Advocates..."
        *ngIf="(isAssistant && (!isInDashboard && !isInPMDashboard && !isInAttorneyDashboard && !isInAdvocateOverview)) || (isExternalAttorneyAssistant && !isInAttorneyDashboard)">
        Working on <u class="app-login-username">{{selectedAdvocate.personalDetails.fullName}} </u>
        <i class="fa fa-angle-down fa-lg" *ngIf="isAssistant && !isInDashboard"></i>
      </span>
      <span class="app-login-menu-link act-on-behalf" id="onBehalf_span"
        title="Select an Advocate to switch to another Advocate"
        *ngIf="isAssistant && (isInDashboard || isInPMDashboard || (isExternalAttorneyAssistant && isInAttorneyDashboard))">
        <label for="" class="debtor-label">Working on </label>
        <!-- <u class="app-login-username">{{selectedAdvocate.personalDetails.fullName}}</u> -->
        <!-- <i class="fa fa-angle-down fa-lg" (click)="selectAdvocates.open()" *ngIf="isAssistant && isInDashboard"></i> -->
        <!-- <select class="app-login-menu-link select-dropdown" style="font-family: FontAwesome, sans-serif;"
          id="onBehalf_select" [(ngModel)]="selectedAdvocate.personalDetails.fullName"
          (change)="selectedChanged(selectedAdvocate.personalDetails.fullName)" width="250px" *ngIf="isAssistant">
          <option #selectAdvocates *ngFor="let advocates of profile.impersonates" [value]="advocates.personalDetails.fullName">
            {{advocates.personalDetails.fullName}}
          </option>
        </select> -->

        <input #selectedAdvocateInput clearButton={true} [(ngModel)]="selectedAdvocate.personalDetails.fullName"
          [typeahead]="profile.impersonates" typeaheadOptionField="advocates.personalDetails.fullName"
          [typeaheadSingleWords]="false" (input)="onInput($event)" (focus)="$event.target.select()"
          class="app-login-menu-link form-control" placeholder="Select or start typing"
          (typeaheadOnSelect)="onAdvocateSelect($event)" [typeaheadOptionsLimit]="50" typeaheadScrollable=true
          typeaheadOptionsInScrollableView="10" [typeaheadMinLength]="0" [dropup]="false">
      </span>
      <span class="app-login-menu-link" *ngIf="isAssistant" (click)="toggleDropdownMenu(tags.menus.avatar)">
        Signed in as <u class="app-login-username">{{profile && profile.personalDetails.fullName}} </u>
        <i class="fa fa-user-circle fa-lg"></i>
      </span>
      <span class="app-login-menu-link" *ngIf="!isAssistant" (click)="toggleDropdownMenu(tags.menus.avatar)">
        Signed in as <u class="app-login-username">{{profile.personalDetails.fullName}} </u>
        <i class="fa fa-user-circle fa-lg" style="margin-left: 5px;"></i>
      </span>
      <div class="card app-login-menu" *ngIf="showAvatarMenu">
        <div class="app-login-menu-item" (click)="openModal(tags.modals.profileModal)">
          <i class="fa fa-cog fa-lg"></i>
          Edit profile
        </div>
        <div class="app-login-menu-item" *ngIf="!isAssistant && !isExternalLawfirm && !isLawfirmAssistant && !isExternalAttorneyAssistant 
          && !isBarSociety && !isAdhoc" (click)="onEditMyTeam()">
          <i class="fa fa-edit fa-lg"></i>
          Edit my team
        </div>
        <div class="app-login-menu-item"
          *ngIf="isAttorney || isLawfirm || isLawfirmAssistant && !isExternalAttorneyAssistant"
          (click)="onAddEditClients()">
          <i class="fa fa-edit fa-lg"></i>
          Add/Edit Clients
        </div>
        <div *ngIf="!isLawfirm && !isExternalLawfirm && !isExternalAttorney && !isExternalAttorneyAssistant
         && !isBarSociety && !isAdhoc " class="app-login-menu-item" (click)="onEditFeeTemplate()">
          <i class="fa fa-edit fa-lg"></i>
          Edit templates
        </div>
        <div class="app-login-menu-item-login" (click)="addNewUser()" *ngIf="!isBarSociety && !isAdhoc">
          <i class="fa fa-user fa-lg"></i>
          Add New User
        </div>
        <div class="app-login-menu-item-login" (click)="outlookSync()"
        *ngIf="userProfileService.userProfile.profileType == profileTypes.ADVOCATE">
        <i class="fa fa-envelope fa-sm"></i>
        Office 365 Setup
      </div>
        <div class="app-login-menu-item-login" (click)="userValidation()"
          *ngIf="sageSetupAllowed && !isAdhoc && !isBarSociety && (isSubscriptionAllowed == 'Active' || SubscriptionStatus == 'Active' || this.SubscriptionStatus == undefined)">
          <i class="fa fa-dollar fa-lg"></i>
          Sage Setup
        </div>
        <div class="app-login-menu-item-login" (click)="userSubscription()"
          *ngIf="(((isSubscriptionAllowed == 'Active' || isSubscriptionAllowed == 'Free Trial') || SubscriptionStatus == 'Active') && (userProfileService.userProfile.profileType != profileTypes.PRACTICE_MANAGER)) || isShowFreeTrialNotification">
          <i class="fa fa-refresh fa-lg"></i>
          Subscriptions
        </div>
        <div class="app-login-menu-item-login" (click)="activateToFullProfile()"
          *ngIf="isAdhoc">
          <i class="fa fa-refresh fa-lg"></i>
          Activate to Full Profile
        </div>
        <div class="app-login-menu-item-login" (click)="signOut()">
          <i class="fa fa-unlock fa-lg"></i>
          Sign out
        </div>
      </div>
    </li>
  </ul>
</nav>
<nav class="navbar navbar-tabs" [class.navbar-tabs-stick]="canStick" *ngIf="!hidden">
  <a *ngIf="!isExternalLawfirm && !isBarSociety && !isExternalAttorney && !isPracticeManager && !isExternalAttorneyAssistant"
    routerLink={{links.dashboard}} class="app-navbar-tab" [class.active]="activeTab === links.dashboard"
    (click)="updateActiveTab(links.dashboard)">
    <i class="fa fa-align-left fa-lg"></i>
    Dashboard
  </a>
  <!-- <a *ngIf="isPracticeManager" routerLink={{links.practiceMDashboard}} class="app-navbar-tab"
    [class.active]="activeTab === links.practiceMDashboard" (click)="updateActiveTab(links.practiceMDashboard)">
    <i class="fa fa-align-left fa-lg"></i>
    Dashboard
  </a> -->
  <a *ngIf="(isExternalLawfirm || isExternalAttorney || isExternalAttorneyAssistant) && !isPracticeManager"
    class="app-navbar-tab" [class.active]="activeTab === links.dashboard"
    (click)="updateActiveTab(links.lawfirm_dashboard)">
    <i class="fa fa-align-left fa-lg"></i>
    Dashboard
  </a>
  <a *ngIf="(signInAsPracticeManager || internalAssistant) && !isPracticeManager && !isBarSociety"
    routerLink={{links.advocate_overview}} class="app-navbar-tab" [class.active]="activeTab === links.advocate_overview"
    (click)="updateActiveTab(links.advocate_overview)">
    <i class="fa fa-align-left fa-lg"></i>
    Advocate overview
  </a>
  <!-- process -->
  <a *ngIf="(!isExternalLawfirm && !isBarSociety && !isExternalAttorney && !isPracticeManager && !isExternalAttorneyAssistant) && (displayDashboard || showReconScreenOnlimitedAssistant)"
    routerLink={{links.process}} class="app-navbar-tab" [class.active]="activeTab === links.process"
    (click)="updateActiveTab(links.process)">
    <i class="far fa-sync-alt fa-lg"></i>
    PROCESS<i *ngIf="count != 0 && activeTab !== links.process && !internalAssistant && !signInAsPracticeManager"
      class="fas fa-circle ng-star-inserted"><b>{{count}}</b></i>
    <i *ngIf="count != 0 && activeTab !== links.process && (internalAssistant || signInAsPracticeManager) && profile.serviceProviderID !== 29723"
      style="right:57%;" class="fas fa-circle ng-star-inserted"><b>{{count}}</b></i>
  </a>
  <a *ngIf="(isExternalLawfirm || isExternalAttorney || isExternalAttorneyAssistant) && displayDashboard && !isBarSociety"
    class="app-navbar-tab" [class.active]="activeTab === links.process"
    (click)="updateActiveTab(links.lawfirm_process)">
    <i class="far fa-sync-alt fa-lg"></i>
    PROCESS
  </a>
  <!-- process -->

  <a *ngIf="!isExternalLawfirm && !isBarSociety && !isExternalAttorney && !isPracticeManager && !isExternalAttorneyAssistant && profile.serviceProviderID !== 29723"
    routerLink={{links.calendar}} class="app-navbar-tab" [class.active]="activeTab === links.calendar"
    (click)="updateActiveTab(links.calendar)">
    <i class="fa fa-calendar-check-o fa-lg"></i>
    Calendar
  </a>
  <a *ngIf="isExternalLawfirm || isExternalAttorney || isExternalAttorneyAssistant" class="app-navbar-tab"
    [class.active]="activeTab === links.calendar" (click)="updateActiveTab(links.lawfirm_calendar)">
    <i class="fa fa-calendar-check-o fa-lg"></i>
    Calendar
  </a>
  <a *ngIf="isPracticeManager && profile.serviceProviderID !== 29723" class="app-navbar-tab"
    [class.active]="activeTab === links.calendar">
    <i class="fa fa-calendar-check-o fa-lg"></i>
    Calendar
  </a>
  <a *ngIf="!isExternalLawfirm && !isExternalAttorney && !isPracticeManager && !isExternalAttorneyAssistant && !isBarSociety"
    routerLink={{links.reports}} class="app-navbar-tab" [class.active]="activeTab === links.reports || isAdhoc"
    (click)="updateActiveTab(links.reports)">
    <i class="fa fa-line-chart fa-lg"></i>
    Reports
  </a>
  <a *ngIf="(isExternalLawfirm || isExternalAttorney || isExternalAttorneyAssistant) && !isPracticeManager && !isBarSociety"
    routerLink={{links.attorneyReports}} class="app-navbar-tab" [class.active]="activeTab === links.reports"
    (click)="updateActiveTab(links.reports)">
    <i class="fa fa-line-chart fa-lg"></i>
    Reports
  </a>
  <a *ngIf="isPracticeManager && profile.serviceProviderID !== 29723" routerLink={{links.practice_reports}}
    class="app-navbar-tab" [class.active]="activeTab === links.practice_reports"
    (click)="updateActiveTab(links.practice_reports)">
    <i class="fa fa-line-chart fa-lg"></i>
    Reports
  </a>

  <a *ngIf="!isExternalLawfirm && !isBarSociety && !isExternalAttorney && !isPracticeManager && !signInAsPracticeManager && !isExternalAttorneyAssistant && !internalAssistant"
    routerLink={{links.search}} class="app-navbar-tab" [class.active]="activeTab === links.search"
    (click)="updateActiveTab(links.search)">
    <i class="fa fa-search fa-lg"></i>
    Search
  </a>
  <a *ngIf="isExternalLawfirm || isExternalAttorney || isExternalAttorneyAssistant" class="app-navbar-tab"
    [class.active]="activeTab === links.search" (click)="updateActiveTab(links.lawfirm_search)">
    <i class="fa fa-search fa-lg"></i>
    Search
  </a>
  <a *ngIf="isPracticeManager && !isBarSociety && profile.serviceProviderID !== 29723" class="app-navbar-tab"
    routerLink={{links.auxcon_admin}} [class.active]="activeTab === links.auxcon_admin"
    (click)="updateActiveTab(links.auxcon_admin)">
    <i class="far fa-user-cog fa-lg"></i>
    Admin
  </a>
  <!-- Manage BlackList -->
  <a *ngIf="isBarSociety" routerLink={{links.manageBlackList}} class="app-navbar-tab mr"
    [class.active]="activeTab === links.manageBlackList" (click)="updateActiveTab(links.manageBlackList)">
    <i class="fa fa-align-left fa-lg"></i>
    Manage Defaulters List
  </a>
  <!--end Manage BlackList -->
  <a *ngIf="!isExternalLawfirm &&  !isBarSociety && !isExternalAttorney && !isPracticeManager && !isExternalAttorneyAssistant && profile.serviceProviderID !== 29723"
    routerLink={{links.messages}} class="app-navbar-tab" [class.active]="activeTab === links.messages"
    (click)="updateActiveTab(links.messages)">
    <i class="fa fa-envelope fa-lg"></i>
    Messages<i title="Unread messages" *ngIf="inboxMessageCounter != 0 && activeTab !== links.messages"
      class="fas fa-circle message-icon"><b>{{inboxMessageCounter}}</b></i>
  </a>
  <a *ngIf="isExternalLawfirm || isExternalAttorney || isExternalAttorneyAssistant" class="app-navbar-tab"
    [class.active]="activeTab === links.messages" (click)="updateActiveTab(links.lawfirm_messages)">
    <i class="fa fa-envelope fa-lg"></i>
    Messages
  </a>
  <a *ngIf="isPracticeManager && !isBarSociety  && profile.serviceProviderID !== 29723"
    routerLink={{links.practiceManagerMessages}} class="app-navbar-tab"
    [class.active]="activeTab === links.practiceManagerMessages"
    (click)="updateActiveTab(links.practiceManagerMessages)">
    <i class="fa fa-envelope fa-lg"></i>
    Messages<i title="Unread messages" *ngIf="pmInboxMessageCounter != 0 && activeTab !== links.practiceManagerMessages"
      class="fas fa-circle pm-message-icon"><b>{{pmInboxMessageCounter}}</b></i>
  </a>
  <span
    *ngIf="!isExternalLawfirm && !isBarSociety && !isExternalAttorney && !isLawfirmAssistant && !isPracticeManager && !isExternalAttorneyAssistant && profile.serviceProviderID !== 29723"
    class="app-navbar-tab tab-add-new" [class.active]="activeTab === links.matters" [class.open]="showAddNewMenu"
    [class.card]="showAddNewMenu" (click)="toggleDropdownMenu(tags.menus.navbar.addNew)">
    <div class="tab-label">
      <i class="fa fa-plus-circle fa-lg"></i>
      Add New
      <i class="fa fa-angle-down fa-lg hide-xs"></i>
    </div>
    <!--#end .tab-label -->
    <div #navbarTabMenu class="app-navbar-tab-menu card" [class.hide]="!showAddNewMenu">

      <ul class="menu-list">
        <li class="menu-item divider" *ngIf="!isQuotation && !isHistoricalData" (click)="openMatter()">
          <i class="fa fa-file-text-o"></i>
          Matter
        </li>
        <li class="menu-item divider" *ngIf="isHistoricalData" (click)="openMatter()">
          <i class="fa fa-file-text-o"></i>
          Historical Data
        </li>
        <li class="menu-item divider" *ngIf="isQuotation" (click)="openQuotation()">
          <i class="fa fa-file-text-o"></i>
          Quotation
        </li>
        <li class="menu-item" *ngIf="!isAttorney && !isLawfirm  && !isAdhoc">
          <a (click)="openModal(tags.modals.newLawFirmModal)">
            <i class="fa fa-black-tie"></i>
            Law firm
          </a>
        </li>
        <li class="menu-item" *ngIf="isAttorney || isLawfirm">
          <a (click)="openModal(tags.modals.newClient)">
            <i class="fa fa-black-tie"></i>
            Client
          </a>
        </li>
        <li class="menu-item" *ngIf="!isAttorney && !isLawfirm  && !isAdhoc">
          <a (click)="openModal(tags.modals.newAttorney)">
            <i class="fa fa-gavel"></i>
            Attorney
          </a>
        </li>
      </ul>
      <!--#end .menu-list -->

    </div>
    <!--#end .app-navbar-tab-menu -->
  </span>
  <!--#end .app-navbar-tab.tab-add-new -->
  <span aria-disabled="true"
    *ngIf="isExternalLawfirm || isExternalAttorney || isLawfirmAssistant || isExternalAttorneyAssistant"
    class="app-navbar-tab tab-add-new" [class.active]="activeTab === links.matters" [class.open]="showAddNewMenu"
    [class.card]="showAddNewMenu" (click)="toggleDropdownMenu(tags.menus.navbar.addNew)">
    <div class="tab-label">
      <i class="fa fa-plus-circle fa-lg"></i>
      Add New
      <i class="fa fa-angle-down fa-lg hide-xs"></i>
    </div>
    <!--#end .tab-label -->
    <div #navbarTabMenu class="app-navbar-tab-menu card" [class.hide]="!showAddNewMenu">

      <ul class="menu-list"
        *ngIf="isExternalLawfirm || isExternalAttorney || isLawfirmAssistant || isExternalAttorneyAssistant">
        <li class="menu-item divider" *ngIf="!isQuotation" (click)="openMatter()">
          <i class="fa fa-file-text-o"></i>
          Matter
        </li>
        <li class="menu-item divider" *ngIf="isQuotation" (click)="openQuotation()">
          <i class="fa fa-file-text-o"></i>
          Quotation
        </li>
        <li class="menu-item"
          *ngIf="!isAttorney && !isLawfirm && !isLawfirmAssistant && !isExternalLawfirm && !isExternalAttorneyAssistant">
          <a (click)="openModal(tags.modals.newLawFirmModal)">
            <i class="fa fa-black-tie"></i>
            Law firm
          </a>
        </li>
        <li class="menu-item" *ngIf="isAttorney || isLawfirm || isLawfirmAssistant">
          <a (click)="openModal(tags.modals.newClient)">
            <i class="fa fa-black-tie"></i>
            Client
          </a>
        </li>
        <li class="menu-item"
          *ngIf="!isAttorney && !isLawfirm && !isLawfirmAssistant && !isExternalLawfirm && !isExternalAttorneyAssistant">
          <a (click)="openModal(tags.modals.newAttorney)">
            <i class="fa fa-gavel"></i>
            Attorney
          </a>
        </li>
      </ul>
      <!--#end .menu-list -->

    </div>
    <!--#end .app-navbar-tab-menu -->
  </span>
  <!--#end .app-navbar-tab.tab-add-new -->
  <span *ngIf="profile.serviceProviderID === 29723" class="app-navbar-tab"></span>
  <span *ngIf="profile.serviceProviderID === 29723" class="app-navbar-tab"></span>
  <span *ngIf="profile.serviceProviderID === 29723" class="app-navbar-tab"></span>
  <!-- <span *ngIf="profile.serviceProviderID === 29723" class="app-navbar-tab"></span> -->
</nav>
<!--#end .navbar.navbar-tabs -->
<app-profile-modal 
  *ngIf="showEditProfileModal" 
  [isVisible]="showEditProfileModal" 
  [tabOptions]="selectedTab"
  [userProfile]="profile" 
  (closeModal)="closeModal($event)"
  (saveUserDetails)="onSaveUserProfile($event)"></app-profile-modal>
<app-new-law-firm-modal [isVisible]="showNewLawFirmModal" [showLawFirmTab]="showLawfirmTab"
  (closeModal)="closeModal($event)"></app-new-law-firm-modal>
<app-new-attorney-modal [isVisible]="showNewAttorneyModal" (closeModal)="closeModal($event)"></app-new-attorney-modal>
<app-add-new-client [isVisible]="showAddClientModal" [(clients)]="otherClient" (closeModal)="closeModal($event)"
  (saveClients)="onSaveOtherClients($event)"></app-add-new-client>
<app-prompt-unsaved-changes-modal title="Switch Advocate"
  message="Please navigate to the dashboard to switch between advocates" [isVisible]="showSwitchAdvocatesPrompt"
  (cancel)="onHideSwitchAdvocatesPrompt()" (continue)="leavePage()" btnTextContinue="Dashboard" btnTextCancel="OK">
</app-prompt-unsaved-changes-modal>
<app-prompt-unsaved-changes-modal title="Full member functionality"
  message="If you would like to become a full Auxcon member, select join and complete the contact form."
  [isVisible]="showJoinAuxconPrompt" (cancel)="onHideJoinAuxconPrompt()" (continue)="onJoin()" btnTextCancel="Cancel"
  btnTextContinue="Join"></app-prompt-unsaved-changes-modal>

<app-prompt-unsaved-changes-modal title="Changing selected profile"
  message="You are about to change the selected profile. Are you sure you want to change?"
  [isVisible]="changeSelectedProfilePrompt" (cancel)="onHideChangingSelectedProfilePrompt()"
  (continue)="onChangeSelectedProfile()" btnTextCancel="Cancel"
  btnTextContinue="Change"></app-prompt-unsaved-changes-modal>

<app-prompt-save-contact-modal [title]="promptTitle" [message]="message" [isVisible]="showSaveContactModelPrompt"
  (cancel)="onHideSaveContactModelPrompt()"></app-prompt-save-contact-modal>

<app-prompt-unsaved-changes-modal title="Update Fee Tariff"
  message="Please consider updating your fee tariff to be in conjunction with your peers. Do you wish to proceed?"
  [isVisible]="showUpdateFeeTeriffPrompt" (cancel)="onHideUpdateFeeTeriffPrompt()" (continue)="onUpdateFeeTeriff()"
  btnTextCancel="No, Cancel" btnTextContinue="Proceed"></app-prompt-unsaved-changes-modal>