import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';

export interface Notes {
  Id: number;
  ObjectId: number;
  Subject: string;
  NoteTypeId: number;
  InternalText: string;
  ExternalText: string;
  UserOwner: number;
  UserCreator: number;
  UserAssigned: number;
  Priority: number;
  CaptureDate: string;
  ActionDate: string;
  ActivityDate: string;
  InstructionResponseId: number;
  InstructionText: string;
  ResolutionText: string;
  InternalInstructionNote: string;
  MutliObjects: number;
  InstructionCaptureDate: string;
  ResolutionCaptureDate: string;
  IsVisibleUserOwner: boolean;
  IsVisibleUserCreator: boolean;
  IsVisibleUserAssigned: boolean;
}

export const DEFAULT_NOTE: Notes = {
  Id: 0,
  ObjectId: 0,
  Subject: '',
  NoteTypeId: 0,
  InternalText: '',
  ExternalText: '',
  UserOwner: 0,
  UserCreator: 0,
  UserAssigned: 0,
  Priority: 0,
  CaptureDate: '',
  ActionDate: '',
  ActivityDate: '',
  InstructionResponseId: 0,
  InstructionText: '',
  ResolutionText: '',
  InternalInstructionNote: '',
  MutliObjects: 0,
  InstructionCaptureDate: '',
  ResolutionCaptureDate: '',
  IsVisibleUserAssigned: false,
  IsVisibleUserCreator: false,
  IsVisibleUserOwner: false
};

export interface NotesObjectHandle {
  data: NotesObject;
  state: {
    isVisible: boolean;
    isOpen: boolean;
    isSelected: boolean;
  };
}

export interface NotesObject {
  Id: number;
  ObjectTypeId: number;
  Category: string;
  ObjectName: string;
  ObjectId: number;
  ObjectTypeDescription: string;
  Subject: string;
  NoteTypeId: number;
  NoteAbout: string;
  InvoiceNoteMatter: string;
  NoteTypeDescription: string;
  InternalText: string;
  ExternalText: string;
  UserOwner: number;
  UserOwnerFullName: string;
  UserCreator: number;
  UserCreatorFullName: string;
  UserAssigned: number;
  UserAssignedFullName: string;
  Priority: number;
  PriorityText: string;
  CaptureDate: string;
  ActionDate: string;
  ActivityDate: string;
  InstructionResponseId: number;
  ResponseText: string;
  InstructionText: string;
  UserOwnerStatus: boolean;
  UserCreatorStatus: boolean;
  UserAssignedStatus: boolean;
  QuestionId: number;
  QuestionText: string;
  ResolutionText: string;
  Personal: boolean;
  InternalInstructionNote: string;
  InstructionCaptureDate: string;
  ResolutionCaptureDate: string;
  MutliObjects: number;
  NoteInsertedLevel: number;
  IsVisibleUserAssigned: boolean;
  IsVisibleUserCreator: boolean;
  IsVisibleUserOwner: boolean;
}

export interface MultiInvoiceNote {
  Id: number;
  InvoiceId: number;
  InvoiceDate: string;
  InvoiceNo: string;
  Matter: string;
  Attorney: string;
  AttorneyRef: string;
  Aging: string;
  Due: number;
  Paid: number;
  InvoiceTotal: number;
  AdvocateStatus: string;
  PracticeManagerStatus: string;
  AdHocCollections: boolean;
  ObjectLinkId: number;
}

export const DEFAULT_MULTI_INVOICE_NOTE: MultiInvoiceNote = {
  Id: 0,
  InvoiceId: 0,
  InvoiceDate: '',
  InvoiceNo: '',
  Matter: '',
  Attorney: '',
  AttorneyRef: '',
  Aging: '',
  Due: 0,
  Paid: 0,
  InvoiceTotal: 0,
  AdvocateStatus: '',
  PracticeManagerStatus: '',
  AdHocCollections: false,
  ObjectLinkId: 0
};

export interface MultiObjectNote {
  LawfirmId: number;
  Lawfirm: string;
  AdHocId: string;
  Notes: Notes;
  MultiInvoiceNote: MultiInvoiceNote[];
}

export const DEFAULT_MULTI_OBJECT_NOTE: MultiObjectNote = {
  LawfirmId: 0,
  Lawfirm: '',
  AdHocId: '',
  Notes: Object.assign({}, DEFAULT_NOTE),
  MultiInvoiceNote: []
};

export interface MultiInvoiceNoteHandle {
  data: MultiInvoiceNote;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean;
  };
}

export const DEFAULT_NOTES_OBJECT: NotesObject = {
  Id: 0,
  ObjectTypeId: 0,
  ObjectName: '',
  Category: '',
  ObjectId: 0,
  ObjectTypeDescription: '',
  Subject: '',
  NoteAbout: '',
  InvoiceNoteMatter: '',
  NoteTypeId: 0,
  NoteTypeDescription: '',
  InternalText: '',
  ExternalText: '',
  UserOwner: 0,
  UserOwnerFullName: '',
  UserCreator: 0,
  UserCreatorFullName: '',
  UserAssigned: 0,
  UserAssignedFullName: '',
  Priority: 0,
  PriorityText: '',
  CaptureDate: '',
  ActionDate: '',
  ActivityDate: '',
  InstructionResponseId: 0,
  ResponseText: '',
  InstructionText: '',
  UserAssignedStatus: false,
  UserCreatorStatus: false,
  UserOwnerStatus: false,
  QuestionId: 0,
  QuestionText: '',
  ResolutionText: '',
  Personal: false,
  InternalInstructionNote: '',
  MutliObjects: 0,
  InstructionCaptureDate: '',
  ResolutionCaptureDate: '',
  NoteInsertedLevel: 0,
  IsVisibleUserAssigned: false,
  IsVisibleUserCreator: false,
  IsVisibleUserOwner: false
};

export interface NoteCategory {
  Id: number;
  Category: string;
  ObjectTypeId: number;
}

export const DEFAULT_NOTE_CATEGORY: NoteCategory = {
  Id: 0,
  Category: '',
  ObjectTypeId: 0
};

export interface NoteTypes {
  Id: number;
  Name: string;
  Description: string;
  CategoryId: number;
  Personal: boolean;
  Instruction: boolean;
  Diary: boolean;
  Comment: boolean;
  DefaultPriority: number;
  InstructionQuestionId: number;
  InstructionQuestion: string;
  ViewRuleId: number;
}

export const DEFAULT_NOTE_TYPE: NoteTypes = {
  Id: 0,
  Name: '',
  Description: '',
  CategoryId: 0,
  Personal: false,
  Instruction: false,
  Diary: false,
  Comment: false,
  DefaultPriority: 0,
  InstructionQuestionId: 0,
  InstructionQuestion: '',
  ViewRuleId: 0
};

export interface InstructionResponses {
  Id: number;
  Responses: string;
  Details: string;
  DisplayView: string;
}

export const DEFAULT_INSTRUCTION_RESPONSES: InstructionResponses = {
  Id: 0,
  Responses: '',
  Details: '',
  DisplayView: ''
};

export interface MessagesInboxStatus {
  Id: number;
  MessageId: number;
  ServiceProviderStatus: boolean;
  ClientStatus: boolean;
}

export const DEFAULT_MESSAGES_INBOX_STATUS: MessagesInboxStatus = {
  Id: 0,
  MessageId: 0,
  ServiceProviderStatus: false,
  ClientStatus: false
};

export interface ClientContactStatus {
  Id: number;
  ServiceProviderId: number;
  ClientId: number;
  IsFavourite: boolean;
  CanCollect: boolean;
  CanCollectReason: string;
}

export const DEFAULT_CLIENT_CONTACT_STATUS: ClientContactStatus = {
  Id: 0,
  ServiceProviderId: 0,
  ClientId: 0,
  IsFavourite: false,
  CanCollect: false,
  CanCollectReason: ''
};

export interface CollectionsDocumentDTOHandle {
  data: CollectionsDocumentDTO;
  state: {
    isVisible: boolean;
    isSelected: boolean;
    isOpen: boolean;
  };
}

export interface CollectionsDocumentDTO {
  CollectionsDocumentId: number;
  ClientId: number;
  ServiceProviderId: number;
  CollectionsGroupId: number;
  DocumentPath: string;
  Status: string;
  DocumentType: string;
  DocumentTypeID: number;
  DateInserted: string;
}

export const DEFAULT_COLLECTION_DOCUMENT_DTO: CollectionsDocumentDTO = {
  CollectionsDocumentId: 0,
  ClientId: 0,
  ServiceProviderId: 0,
  CollectionsGroupId: 0,
  DocumentPath: '',
  Status: '',
  DocumentType: '',
  DocumentTypeID: 0,
  DateInserted: ''
};

export interface CollectionDebtorProgress {
  DebtorId: number;
  Debtor: string;
  ClientId: number;
  ClientName: string;
  ClientStreetAddress: string;
  ActionDate: string;
  ActivityDate: string;
  DiaryDate: string;
  Description: string;
  InstructionText: string;
  ActionType: string;
  Action: string;
  LoggedBy: string;
  Source: string;
  InvoiceNo: string;
  SourceId: number;
  MessageId: number;
  LoggedById: number;
}

export interface CollectionDebtorProgressHandle {
  data: CollectionDebtorProgress;
  state: {
    isOpen: boolean;
    isVisible: boolean;
    isSelected: boolean;
  }
}

@Injectable()
export class NotesService {

  constructor(
    private api: ApiService,
    private http: HttpClient,
  ) { } // end constructor()

  getNotes(userAssigned: number, objectId: number, objectTypeId: number): Observable<any> {
    return this.http.get<any>(this.api.endpoints.notes +
      '?UserAssigned=' + userAssigned + '&objectId=' + objectId + '&objectTypeId=' + objectTypeId,
      this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end getNotes{}

  getClientContactStatus(serviceProviderId: number, clientId: number): Observable<any> {
    return this.http.get<any>(this.api.endpoints.collectionsStatus +
      '/ClientContactStatus?ServiceProviderId=' + serviceProviderId +
      '&ClientId=' + clientId, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end getClientContactStatus()

  getAdHocRequest(AdhocId: string): Observable<any> {
    console.log('GET AD-HOC REQUEST: ', this.api.endpoints.notes +
      '/AdHocRequest?Id=' + AdhocId);
    return this.http.get(this.api.endpoints.notes +
      '/AdHocRequest?Id=' + AdhocId, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end getAdHocRequest()

  getAdHocInvoices(AdhocId: string): Observable<any> {
    return this.http.get<any>(this.api.endpoints.notes +
      '/AdHocInvoices?Id=' + AdhocId, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end getAdHocInvoices()

  getAdHocRequestNote(ObjectLinkId: number): Observable<any> {
    return this.http.get<any>(this.api.endpoints.notes +
      '/AdHocRequestNote?ObjectLinkId=' + ObjectLinkId, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end getAdHocRequestNote()

  getCollectionDocumentList(advocateId: number, clientId: number): Observable<any> {
    return this.http.get<any>(this.api.endpoints.collectorNotes +
      '/DocumentList?ServiceProviderId=' + advocateId +
      '&ClientId=' + clientId, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end getCollectionDocumentList{}

  getAllNotes(advocateId: number): Observable<any> {
    return this.http.get<any>(this.api.endpoints.notes +
      '/AllNotes?advocateId=' + advocateId,
      this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end getAllNotes()

  getNoteById(advocateId: number, noteId: number): Observable<any> {
    return this.http.get<any>(this.api.endpoints.notes +
      '/NoteById?advocateId=' + advocateId + '&Id=' + noteId,
      this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end getNoteById()

  getNotesObjects(objectId: number): Observable<any> {
    return this.http.get<any>(this.api.endpoints.notes +
      '/AllNotesObjects?objectId=' + objectId,
      this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end getNotesObjects()

  // getAllPMNotes(practiseManagerId: number, SearchTerm: string, Skip: number, Take: number): Observable<any> {
  //   return this.http.get<any>(this.api.endpoints.notes +
  //     '/AllPMNotes?practiseManagerId=' + practiseManagerId + '&SearchTerm=' + SearchTerm + '&Skip=' + Skip + '&Take=' + Take,
  //     this.api.httpOptions)
  //     .pipe(catchError(this.api.handleError)
  //     );
  // } // end getAllPMNotes()

  getAllPMNotes(practiseManagerId: number, searchTerm: string, skip: number, take: number): Observable<any> {
    return this.http.get<any>(`${this.api.endpoints.notes}/AllPMNotes`, {
        params: {
            practiseManagerId: practiseManagerId.toString(),
            searchTerm: searchTerm,
            skip: skip.toString(),
            take: take.toString()
        },
        headers: this.api.httpOptions.headers
    }).pipe(catchError(this.api.handleError));
}

  getNoteCategory(): Observable<any> {
    return this.http.get<any>(this.api.endpoints.notes +
      '/NoteCategory', this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end getNoteCategoty()

  getNoteSubCategory(categoryId: number): Observable<any> {
    return this.http.get<any>(this.api.endpoints.notes +
      '/NoteSubCategory?categoryId=' + categoryId,
      this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end getNoteSubCategory()

  getInstructionResponses(questionId: number): Observable<any> {
    return this.http.get<any>(this.api.endpoints.notes +
      '/InstructionResponses?questionId=' + questionId,
      this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end getInstructionResponses()

  getPMInstructionResponses(): Observable<any> {
    return this.http.get<any>(this.api.endpoints.notes +
      '/PMInstructionResponses', this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end getPMInstructionResponses()

  // getAdvUnreadMessagesCounter(advocateId: number): Observable<any> {
  //   return this.http.get<any>(this.api.endpoints.notes +
  //     '/AdvUnreadMessagesCounter?advocateId=' + advocateId,
  //     this.api.httpOptions)
  //     .pipe(catchError(this.api.handleError)
  //   );
  // } // end getAdvUnreadMessagesCounter()

  getPMUnreadMessagesCounter(pmId: number): Observable<any> {
    return this.http.get<any>(this.api.endpoints.notes +
      '/PMUnreadMessagesCounter?pmId=' + pmId,
      this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end getPMUnreadMessagesCounter()

  getMultipleObjectNotes(advocateId: number, multiObjectId: number): Observable<any> {
    return this.http.get<any>(this.api.endpoints.notes +
      '/AllMultiObjectNotes?advocateId=' + advocateId + '&multiObjectId=' + multiObjectId,
      this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end getMultipleobjectNotes()

  putNoteInboxStatus(noteDetail: any): Observable<any> {
    return this.http.put<any>(this.api.endpoints.notes +
      '/PutNoteInboxStatus', noteDetail, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end putNoteInboxStatus()

  getLastInstructionNote(userCreator: number, userAssigned: number, objectId: number, noteId: number): Observable<any> {
    return this.http.get<any>(this.api.endpoints.notes +
      '/LastInstructionNote?userCreator=' + userCreator + '&userAssigned=' + userAssigned
      + '&objectId=' + objectId + '&noteId=' + noteId, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end putNoteInboxStatus()

  putMultiInvoiceObject(multiInvoice: MultiInvoiceNote): Observable<any> {
    return this.http.put<any>(this.api.endpoints.notes +
      '/PutMultipleInvoice', multiInvoice, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end putMultiInvoiceObject()

  getDebtorProgressReport(clientId: number): Observable<any> {
    return this.http.get<any>(this.api.endpoints.debtorProgress +
      '?ServiceProviderId=' + this.api.serviceProviderID + '&ClientId=' + clientId, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      )
  } // end getDebtorProgressReport()

  putClientMessagesInboxStatus(data: MessagesInboxStatus): Observable<any> {
    return this.http.put<MessagesInboxStatus>(this.api.endpoints.notes +
      '/ClientMessageInboxStatus', data, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end putClientMessagesInboxStatus()

  putClientContactStatus(data: ClientContactStatus): Observable<ClientContactStatus> {
    const apidata = {
      id: data.Id,
      serviceProviderId: data.ServiceProviderId,
      clientId: data.ClientId,
      isFavourite: data.IsFavourite,
      canCollect: data.CanCollect,
      canCollectReason: data.CanCollectReason
    }
    return this.http.put<any>(this.api.endpoints.collectionsStatus +
      '/PutClientContactStatus?ServiceProviderId=' + this.api.serviceProviderID
      , apidata, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end putClientContactStatus {}

  putPMNoteDismiss(noteId: number): Observable<any> {
    return this.http.put<any>(this.api.endpoints.notes +
      '/PMNoteDismiss?userId=' + this.api.serviceProviderID + '&noteId=' + noteId, null,
      this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // putPMNoteDismiss()

  postClientMessagesInboxStatus(data: MessagesInboxStatus): Observable<any> {
    return this.http.post<MessagesInboxStatus>(this.api.endpoints.notes +
      '/ClientMessagesInboxStatus', data, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end putClientMessagesInboxStatus()

  putRemoveAdHoc(invoiceId: number, isPracticeManager: boolean): Observable<any> {
    return this.http.put<any>(this.api.endpoints.notes +
      '/RemoveAdHocRequest?InvoiceId=' + invoiceId + '&isPracticeManager=' + isPracticeManager, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  }

  postNote(note: Notes): Observable<any> {
    const postNotes = {
      actionDate: note.ActionDate,
      captureDate: note.CaptureDate,
      externalText: note.ExternalText,
      instructionResponseId: note.InstructionResponseId,
      instructionText: note.InstructionText,
      internalText: note.InternalText,
      noteTypeId: note.NoteTypeId,
      objectId: note.ObjectId,
      priority: note.Priority,
      subject: note.Subject,
      userAssigned: note.UserAssigned,
      userCreator: note.UserCreator,
      userOwner: note.UserOwner
    };
    return this.http.post<Notes>(this.api.endpoints.notes +
      '/AddNewNote', postNotes, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end postNote()

  postInstructionNote(note: Notes): Observable<any> {
    const postNotes = {
      actionDate: note.ActionDate,
      captureDate: note.CaptureDate,
      instructionCaptureDate: note.InstructionCaptureDate,
      externalText: note.ExternalText,
      instructionResponseId: note.InstructionResponseId,
      instructionText: note.InstructionText,
      internalText: note.InternalText,
      noteTypeId: note.NoteTypeId,
      objectId: note.ObjectId,
      priority: note.Priority,
      subject: note.Subject,
      userAssigned: note.UserAssigned,
      userCreator: note.UserCreator,
      userOwner: note.UserOwner,
      isVisibleUserOwner: note.IsVisibleUserOwner
    };
    return this.http.post<Notes>(this.api.endpoints.notes +
      '/AddNewInstructionNote', postNotes, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end postInstructionNote()

  postInvoiceInstructionNote(note: Notes): Observable<any> {
    const postNotes = {
      actionDate: note.ActionDate,
      captureDate: note.CaptureDate,
      instructionCaptureDate: note.InstructionCaptureDate,
      externalText: note.ExternalText,
      instructionResponseId: note.InstructionResponseId,
      instructionText: note.InstructionText,
      internalText: note.InternalText,
      noteTypeId: note.NoteTypeId,
      objectId: note.ObjectId,
      priority: note.Priority,
      subject: note.Subject,
      userAssigned: note.UserAssigned,
      userCreator: note.UserCreator,
      userOwner: note.UserOwner
    };
    return this.http.post<Notes>(this.api.endpoints.notes +
      '/AddNewInvoiceInstructionNote', postNotes, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end postInvoiceInstructionNote()

  postMultipeNote(notesObject: MultiObjectNote): Observable<any> {
    return this.http.post<Notes>(this.api.endpoints.notes +
      '/AddMultiInvoiceNote', notesObject, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end postMultipeNote()

  postInstructionOnMultipeNote(notesObject: MultiObjectNote): Observable<any> {
    // console.log('DO WE HAVE DATA TO POST HERE: ', notesObject);
    return this.http.post<Notes>(this.api.endpoints.notes +
      '/AddInstructionOnMultiInvoiceNote', notesObject, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end postInstructionOnMultipeNote()

  postAdHocInstructionNote(notesObject: MultiObjectNote, isAdHocCollections: boolean): Observable<any> {
    // console.log('INSTRUCTION FROM ADVOCATE TO BE SAVE: ');
    return this.http.post<Notes>(this.api.endpoints.notes +
      '/AddAdHocRequestInstruction?isAdHocCollections=' + isAdHocCollections, notesObject, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end postAdHocInstructionNote()

  approveAdHocInstructionNote(notesObject: MultiObjectNote): Observable<any> {
    // console.log('INSTRUCTION FROM ADVOCATE TO BE SAVE: ');
    return this.http.post<Notes>(this.api.endpoints.notes +
      '/ApproveAdHocRequest', notesObject, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end approveAdHocInstructionNote()

  putNote(note: Notes): Observable<any> {
    const postNotes = {
      id: note.Id,
      actionDate: note.ActionDate,
      captureDate: note.CaptureDate,
      externalText: note.ExternalText,
      instructionResponseId: note.InstructionResponseId,
      instructionText: note.InstructionText,
      internalText: note.InternalText,
      noteTypeId: note.NoteTypeId,
      objectId: note.ObjectId,
      priority: note.Priority,
      subject: note.Subject,
      userAssigned: note.UserAssigned,
      userCreator: note.UserCreator,
      userOwner: note.UserOwner,
      resolutionText: note.ResolutionText,
      mutliObjects: note.MutliObjects,
      instructionCaptureDate: note.InstructionCaptureDate,
      resolutionCaptureDate: note.ResolutionCaptureDate,
      isVisibleUserOwner: note.IsVisibleUserOwner,
      isVisibleUserCreator: note.IsVisibleUserCreator,
      isVisibleUserAssigned: note.IsVisibleUserAssigned
    };

    return this.http.put<Notes>(this.api.endpoints.notes +
      '/UpdateNote', postNotes, this.api.httpOptions)
      .pipe(catchError(this.api.handleError)
      );
  } // end postNote()
} // end NotesService{}
