<div class="invoiced-collected-container">
    <nav class="invoiced-collected-nav">
        <a class="tab" [class.active]="activeTab === 'graph'" (click)="onTabClick('graph')">Graph</a>
        <a class="tab" [class.active]="activeTab === 'vat'" style="text-transform: capitalize !important;" (click)="onTabClick('vat')">VAT Information</a>
        <a class="tab" [class.active]="activeTab === 'tax'" (click)="onTabClick('tax')">Tax Information</a>
        <!-- <a class="tab" [class.active]="activeTab === 'table'" (click)="onTabClick('table')">Table</a> -->

    </nav>
    <div class="well" *ngIf="activeTab === 'graph'">
        <div class="row total m-0">
            <div class="col-md-12 text-center">
                <span class="invoice" *ngIf="isRegisteredforVAT">VAT: {{totalVATAmount | currency: currencySymbol: currencySymbol:'1.2-2'}}
                    <i class="far fa-info-circle tooltipp">
                        <div class="bottom" style="text-align: center">
                          <span class="fa fa-info-circle" style="font-size: 25px; padding-top: 9px; padding-bottom: 5px;"></span>
                          <p>VAT figure for the 2 months accumulated cycle</p>
                          <i></i>
                        </div>
                      </i>
                </span>
                <span class="collection">Provisional Tax: {{totalProvisionalTax | currency: currencySymbol: currencySymbol:'1.2-2'}}
                    <i class="far fa-info-circle tooltipp">
                        <div class="bottom" style="text-align: center">
                          <span class="fa fa-info-circle" style="font-size: 25px; padding-top: 9px; padding-bottom: 5px;"></span>
                          <p>Provisional Tax figure for the current month</p>
                          <i></i>
                        </div>
                      </i>
                </span>
                <span class="ratio">Provisional Tax (PTD): {{totalPTP | currency: currencySymbol: currencySymbol:'1.2-2'}}
                    <i class="far fa-info-circle tooltipp">
                        <div class="bottom" style="text-align: center">
                          <span class="fa fa-info-circle" style="font-size: 25px; padding-top: 9px; padding-bottom: 5px;"></span>
                          <p>Accumulated provision tax for current tax period (excl VAT)</p>
                          <i></i>
                        </div>
                      </i>
                </span>
            </div>
        </div>
        <div class="row m-0 p-0">
            <div id="TaxLiabilityChart"></div>
        </div>
        <div class="row m-0 p-0">
            <table style="width: 20%;">
                <tr>
                    <td>
                        <div
                            style="display: inline-block; height: 15px; width: 15px; background: #5e97d8; border-radius: 2px;">
                        </div>
                        <span class="ml-2" style="vertical-align: top;">VAT</span>
                    </td>
                    <td style="width: 45%;">
                        <div
                            style="display: inline-block; height: 15px; width: 15px; background: #8abd37; border-radius: 2px;">
                        </div>
                        <span class="ml-2" style="vertical-align: top;">Provisional Tax</span>
                    </td>
                    <td>
                        <div
                            style="display: inline-block; height: 15px; width: 15px; background: #eb9e02; border-radius: 2px;">
                        </div>
                        <span class="ml-2" style="vertical-align: top;">PTD</span>
                    </td>
                </tr>
            </table>
        </div>
        <div class="row m-0 p-0">
            <div class="well text-center" style="width: 56%; padding-top: 11px;">
                <span *ngIf="isRegisteredforVAT" style="padding-right: 10px;"><b>Current VAT period:</b> {{currentPreriodFromDate}} / {{currentPreriodToDate}} </span> <span  *ngIf="isRegisteredforVAT">|</span>                  
                <span style="padding-right: 10px; padding-left: 10px;"><b>Last statement date:</b> {{lastStatementDate | date:'yyyy-MM-dd'}}</span>|
                <span style="padding-left: 10px;"><b>Last transaction date:</b> {{lastTransactionDate | date:'yyyy-MM-dd'}}</span> 
            </div>
        </div>
        <div class="row m-0 p-0">
            <div class="well" style="width: 56%; padding-top: 21px; float: left;">
                <h2 style="font-size: 12px;">Disclaimer:</h2>
                <p style="font-size: 10px;">The Tax Liability information contained on this site is provided for awareness purposes only, is dependent on your input and does not constitute a formal tax calculation that can be used to complete your tax returns. You should not rely upon the information that has been provided as a basis for making any business, legal or any other decisions.</p>
            </div>
        </div>
    </div>

    <div class="well" style="text-align: center;" *ngIf="activeTab === 'vat'">
        <div class="row">
            <!-- PDF Viewer -->
            <div class="col-12">
                <iframe
                    [src]="pdfSafeUrl"
                    width="80%"
                    height="500px"
                    style="border: none;">
                </iframe>
            </div>
        </div>
    </div>
    <div class="well" style="text-align: center;" *ngIf="activeTab === 'tax'">
        <div class="row">
            <!-- PDF Viewer -->
            <div class="col-12">
                <iframe
                    [src]="pdfSafeUrl"
                    width="80%"
                    height="500px"
                    style="border: none;">
                </iframe>
            </div>
        </div>
    </div>
</div>