import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { LoadingService } from '../../../services/messaging/loading/loading.service';
import { Contacts, ContactsService, DEFAULT_CONTACTS } from '../../../services/contacts/contacts.service';

@Component({
  selector: 'app-assign-advocate-to-secretary',
  templateUrl: './assign-advocate-to-secretary.component.html',
  styleUrls: ['./assign-advocate-to-secretary.component.scss']
})
export class AssignAdvocateToSecretaryComponent implements OnInit {

  title = 'Search for an Advocate';
  canViewFinancials = true;
  permissionId = 1;

  selectedAdvocate: Contacts = DEFAULT_CONTACTS;
  @Input() isVisible: boolean;
  @Input() advocateList: Contacts[] = [];
  @Input() selectedSecretary: Contacts;
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() select: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private loadingService: LoadingService,
    private contactService: ContactsService
  ) { } // end constrictor()

  // onInput(event) {
  //   if (event.inputType === 'deleteContentBackward' || Boolean(event.target.value.length < 3)) {
  //   }
  // } // end onInput()

  ngOnInit(): void {
    this.selectedAdvocate = {
      AccountHolder: '',
      AccountNumber: '',
      AccountType: '',
      BranchCode: '',
      Cell: '',
      CollectThreshold: '',
      ContactContactType: 0,
      Locality: '',
      ContactLocality: 0,
      DefaultDailyRate: 0,
      DefaultHourlyRate: 0,
      DefaultPerKilometerRate: 0,
      Docex: '',
      Email: '',
      Fax: '',
      FeeTarget: 0,
      FullName : '',
      Id: 0,
      Identity: '',
      Image: '',
      IncomeTarget: 0,
      LastStatementDownload: '',
      Level: '',
      OfficeRoom: '',
      Phone: '',
      PostalAddress: '',
      SecurityLinks: '',
      Speciality: '',
      Status: '',
      StreetAddress: '',
      Suburb: '',
      ToCollect: 0,
      UserName: '',
      Vatno: '',
      WebSite: '',
      ZipCode: '',
      IsSelected: false,
      AdmissionDay : null,
      AdmissionMonth : null,
      AdmissionYear : null,
      AdmissionDate : null,
      AdmissionDateStr : '',
      RegionalBarId : 0,
      RegionalBarName : '',
      ChamberID: 0,
      TenantID: '',
      Domain: '',
      ContactTypeID: 2,
      Ethincity: 'African',
      Gender: 'Male',
      Chamber: ''
    };
  } // end ngOnInit()

  onDismiss() {
    this.permissionId = 1;
    this.selectedAdvocate = {
      AccountHolder: '',
      AccountNumber: '',
      AccountType: '',
      BranchCode: '',
      Cell: '',
      CollectThreshold: '',
      ContactContactType: 0,
      Locality: '',
      ContactLocality: 0,
      DefaultDailyRate: 0,
      DefaultHourlyRate: 0,
      DefaultPerKilometerRate: 0,
      Docex: '',
      Email: '',
      Fax: '',
      FeeTarget: 0,
      FullName : '',
      Id: 0,
      Identity: '',
      Image: '',
      IncomeTarget: 0,
      LastStatementDownload: '',
      Level: '',
      OfficeRoom: '',
      Phone: '',
      PostalAddress: '',
      SecurityLinks: '',
      Speciality: '',
      Status: '',
      StreetAddress: '',
      Suburb: '',
      ToCollect: 0,
      UserName: '',
      Vatno: '',
      WebSite: '',
      ZipCode: '',
      IsSelected: false,
      AdmissionDay : null,
      AdmissionMonth : null,
      AdmissionYear : null,
      AdmissionDate : null,
      AdmissionDateStr : '',
      RegionalBarId : 0,
      RegionalBarName : '',
      ChamberID: 0,
      TenantID: '',
      Domain: '',
      ContactTypeID: 2,
      Ethincity: 'African',
      Gender: 'Male',
      Chamber: ''
    };
    this.closeModal.emit('assign-advocate')
  } // end onDismiss()

  onSelectContact(event: TypeaheadMatch): void {
    this.selectedAdvocate = {
      AccountHolder: '',
      AccountNumber: '',
      AccountType: '',
      BranchCode: '',
      Cell: '',
      CollectThreshold: '',
      ContactContactType: 0,
      Locality: '',
      ContactLocality: 0,
      DefaultDailyRate: 0,
      DefaultHourlyRate: 0,
      DefaultPerKilometerRate: 0,
      Docex: '',
      Email: '',
      Fax: '',
      FeeTarget: 0,
      FullName : '',
      Id: 0,
      Identity: '',
      Image: '',
      IncomeTarget: 0,
      LastStatementDownload: '',
      Level: '',
      OfficeRoom: '',
      Phone: '',
      PostalAddress: '',
      SecurityLinks: '',
      Speciality: '',
      Status: '',
      StreetAddress: '',
      Suburb: '',
      ToCollect: 0,
      UserName: '',
      Vatno: '',
      WebSite: '',
      ZipCode: '',
      IsSelected: false,
      AdmissionDay : null,
      AdmissionMonth : null,
      AdmissionYear : null,
      AdmissionDate : null,
      AdmissionDateStr : '',
      RegionalBarId : 0,
      RegionalBarName : '',
      ChamberID: 0,
      TenantID: '',
      Domain: '',
      ContactTypeID: 2,
      Ethincity: 'African',
      Gender: 'Male',
      Chamber: ''
    };
    this.selectedAdvocate = event.item;
  } // end onSelectContact()

  toggleViewFinancials() {
    this.canViewFinancials = !this.canViewFinancials;
    if (this.canViewFinancials) {
      this.permissionId = 1;
    } else {
      this.permissionId = 2;
    }
  } // end toggleViewFinancials()

  save() {
    this.loadingService.showOverlay();
    this.contactService.putAssignAdvocateToAssistant(this.selectedSecretary.Id, this.selectedAdvocate.Id, this.permissionId).subscribe({ next: (data) => {
      // On next
    },
    error: (error) => {
      // On next
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      this.select.emit('assigned');
      this.permissionId = 1;
      this.selectedAdvocate = {
        AccountHolder: '',
        AccountNumber: '',
        AccountType: '',
        BranchCode: '',
        Cell: '',
        CollectThreshold: '',
        ContactContactType: 0,
        Locality: '',
        ContactLocality: 0,
        DefaultDailyRate: 0,
        DefaultHourlyRate: 0,
        DefaultPerKilometerRate: 0,
        Docex: '',
        Email: '',
        Fax: '',
        FeeTarget: 0,
        FullName : '',
        Id: 0,
        Identity: '',
        Image: '',
        IncomeTarget: 0,
        LastStatementDownload: '',
        Level: '',
        OfficeRoom: '',
        Phone: '',
        PostalAddress: '',
        SecurityLinks: '',
        Speciality: '',
        Status: '',
        StreetAddress: '',
        Suburb: '',
        ToCollect: 0,
        UserName: '',
        Vatno: '',
        WebSite: '',
        ZipCode: '',
        IsSelected: false,
        AdmissionDay : null,
        AdmissionMonth : null,
        AdmissionYear : null,
        AdmissionDate : null,
        AdmissionDateStr : '',
        RegionalBarId : 0,
        RegionalBarName : '',
        ChamberID: 0,
        TenantID: '',
        Domain: '',
        ContactTypeID: 2,
        Ethincity: 'African',
        Gender: 'Male',
        Chamber: ''
      };
      this.onDismiss();
      this.loadingService.hideOverlay();
    }
  });
  } // end save()

} // end AssignAdvocateToSecretary {}
