import {
  Component,
  OnInit,
  ViewChild,
  OnChanges,
  SimpleChanges
} from '@angular/core';

import {
  FromCalendarItem,
  DEFAULT_CALENDAR_ITEM,
  FromCalendarItemsService
} from '../../services/from-calendar-items/from-calendar-items.service';
import {
  MattersService,
  Matter,
  DEFAULT_MATTER
} from '../../services/matters/matters.service';
import {
  SnackbarsService,
  Snack,
  SnackType
} from '../../services/messaging/snackbars/snackbars.service';
import * as moment from 'moment';
import {
  SubCategory,
  SubCategoriesService,
  DEFAULT_SUB_CATEGORY
} from '../../services/sub-categories/sub-categories.service';
import {
  LoadingService
} from '../../services/messaging/loading/loading.service';
import { ActivatedRoute } from '@angular/router';
import {
  DEFAULT_ACTIVITY_LOGS,
  ActivityLogs,
  ActivityLogsService
} from '../../services/activity-logs/activity-logs.service';
import { UserProfileService } from '../../services/user-profile/user-profile.service';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import { environment } from '../../../environments/environment'
import { UtilitiesService } from '../../../app/services/utilities.service';

export interface EventHandle {
  id: number;
  title: string;
  start: string;
  end: string;
  allDay: boolean;
  data: FromCalendarItem;
  color?: string;
  className?: string;
  textColor?: string;
  eventBackgroundColor: string;
  eventColor: string;
  eventTextColor: string;
  eventBorderColor: string;
}

const TITLE_PREFIX = 'Calendar | ';
const EVENT_COLOR_DEFAULT = '#dbbb2d';
const EVENT_COLOR_SENT = '#aaa69d';

const OUTLOOK_EVENT_COLOR_DEFAULT = '#3887BE';

@Component({
  selector: 'app-advocate-calendar',
  templateUrl: './advocate-calendar.component.html',
  styleUrls: ['./advocate-calendar.component.scss']
})
export class AdvocateCalendarComponent implements OnInit, OnChanges {

  @ViewChild('ucCalendar', { static: false }) ucCalendar: FullCalendarComponent;

  //#region New calendar component
  events = [];
  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prev today next',
      center: 'title',
      right: 'dayGridMonth timeGridWeek timeGridDay listWeek'
    },
    initialView: 'timeGridWeek',
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    // titleFormat: 'DD MMMM YYYY'
  };;
  //#endregion New calendar component

  title: string = TITLE_PREFIX;
  showAddFeeCalendarModal: boolean;
  matters: Matter[];
  sentMatters: Matter[];
  subCategories: SubCategory[];
  calendarItems: FromCalendarItem[];
  // events: EventHandle[] = [];
  activeEvent: EventHandle;
  view: any;

  tabs: string[] = [
    'month',
    'agendaWeek',
    'agendaDay',
    'list'
  ];
  activeTab = 'agendaWeek';

  // calendarOptions: Options;
  activeCalendarItem: FromCalendarItem;
  selectedStartTime: string;
  selectedEndTime: string;
  selectedMatter: Matter;
  selectedSubCategory: SubCategory;

  // Activity logs
  activityLog: ActivityLogs = Object.assign({}, DEFAULT_ACTIVITY_LOGS);

  private eventColorDefault = EVENT_COLOR_DEFAULT;
  private outlookEventColorDefault = OUTLOOK_EVENT_COLOR_DEFAULT;
  private eventColorSent = EVENT_COLOR_SENT;
  loadedStartMonth: number;
  loadedStartYear: number;
  loadedEndMonth: number;
  loadedEndYear: number;
  loadedDateRanges: { startMonth: number; startYear: number; endMonth: number; endYear: number }[] = [];
  isOutlookCalendarIntegrate: boolean = false;
  outlookEventId: any;
  openedWindow: any;
  isOutlookCalendarEntry: boolean = false;
  isOutlookTokenError: boolean = false;

  profileTypes = {
    ADVOCATE: 'Advocate',
    ASSISTANT: 'Assistant',
    ATTORNEY: 'Attorney',
    PRACTICE_MANAGER: 'Practice Manager',
    EXTERNAL_ATTORNEY: 'Attorney (External)',
    LAWFIRM: 'Lawfirm',
    EXTERNAL_LAWFIRM: 'Lawfirm (External)'
  };

  showHideDailyEntry: boolean;

  constructor(
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private mattersService: MattersService,
    private snackbarsService: SnackbarsService,
    private userProfileService: UserProfileService,
    private activityLogService: ActivityLogsService,
    private subCategoriesService: SubCategoriesService,
    private fromCalendarItemsService: FromCalendarItemsService,
    private util: UtilitiesService
  ) {
    document.title = route.snapshot.data.title;
    this.title += this.activeTab.replace('agenda', '');
  } // end constructor()

  ngOnInit() {    
    // const currentDate = new Date();
    // let startMonth = currentDate.getMonth() + 1;
    // let startYear = currentDate.getFullYear();
    // let endMonth = currentDate.getMonth() + 1;
    // let endYear = startYear;
    // if (this.loadedStartMonth < 0) {
    //   this.loadedStartMonth += 12;
    //   this.loadedStartYear -= 1;
    // }

    if (this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER) {
      this.fromCalendarItemsService.checkCalendarPermission().subscribe({
        next: (res: any) => {
          if (res.status === true)
            this.isOutlookCalendarIntegrate = true;
          else
            this.isOutlookCalendarIntegrate = false;
        },
        error: (error) => {
          console.log(error);
        },
        complete: () => { }
      });
    }
    else {
      if (localStorage.getItem("isOutlookCalendarIntegrated") && localStorage.getItem("isOutlookCalendarIntegrated") !== "false")
        this.isOutlookCalendarIntegrate = true;
      else if (localStorage.getItem("isOutlookCalendarIntegrated") === "false")
        this.isOutlookCalendarIntegrate = false;
      else {
        let userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
        this.isOutlookCalendarIntegrate = userProfile.outlookCalendarRefreshToken && userProfile.outlookCalendarRefreshToken !== null ? true : false;
      }
    }

    const snack: Snack = {
      label: 'Loading Calendar...',
      action: null
    };
    this.snackbarsService.snackbarComponent.make(snack, 5000).show();
    this.loadEventDetails();
    this.activeCalendarItem = Object.assign({}, DEFAULT_CALENDAR_ITEM);
    // this.updateLoadedDateRanges(startMonth, startYear, endMonth, endYear);
    this.subCategoriesService.getSubCategoryAll().subscribe({
      next:
        (subCategories) => {
          this.subCategories = subCategories;
        },
      error: (error) => {
        const msg = 'Error loading sub-categories.';
        console.error(msg, error);
        snack.label = msg;
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack, 5000).show();
      },
      complete: () => {
        this.subCategories.sort((a: SubCategory, b: SubCategory) => {
          return a.Category.localeCompare(b.Category) || a.SubCategory.localeCompare(b.SubCategory);
        });
      }
    });
  } // end ngOnInit()

  ngOnChanges(changes: SimpleChanges) {
    if (changes.view &&
      changes.view.currentValue &&
      changes.view.currentValue !== changes.view.previousValue) {
      this.loadEvents();
    }
  } // end ngOnChanges{}

  changeTab(tab: string) {
    this.activeTab = tab;
    this.title = TITLE_PREFIX + this.activeTab.replace('agenda', '');
  } // end changeTab()

  createEventHandleFromCalendarItem(calendarItem: FromCalendarItem): EventHandle {
    const endTime = (!calendarItem.ShowInList && calendarItem.ToDate) ? moment(calendarItem.ToDate).format() : null;
    const event: EventHandle = {
      id: calendarItem.CalendarItemID,
      title: calendarItem.Subject,
      start: moment(calendarItem.FromDate).format(),
      end: endTime,
      allDay: calendarItem.ShowInList,
      data: calendarItem,
      color: calendarItem.MatterStatus ? (calendarItem.MatterStatus.localeCompare('Sent') !== 0 ? (calendarItem.MatterStatus.localeCompare('') === 0 ? this.outlookEventColorDefault : this.eventColorDefault) : this.eventColorSent) : this.eventColorDefault,
      eventBackgroundColor: calendarItem.MatterStatus ? (calendarItem.MatterStatus.localeCompare('Sent') !== 0 ? this.eventColorDefault : this.eventColorSent) : this.eventColorDefault,
      eventColor: calendarItem.MatterStatus ? (calendarItem.MatterStatus.localeCompare('Sent') !== 0 ? this.eventColorDefault : this.eventColorSent) : this.eventColorDefault,
      eventTextColor: '#212529',
      eventBorderColor: calendarItem.MatterStatus ? (calendarItem.MatterStatus.localeCompare('Sent') !== 0 ? this.eventColorDefault : this.eventColorSent) : this.eventColorDefault,
      className: calendarItem.isAuxconCalendarEntry === false ? 'fc-event-outlook' : !calendarItem.CanEdit ? 'fc-event-sent' : 'fc-v-event-future',
      textColor: '#FFF',
    };
    return event;
  } // end createEventHandleFromCalendarItem()

  clearEvents() {
    this.events = [];
  } // end clearEvents()

  loadEvents() {
    if (this.calendarItems) {
      this.clearEvents();

      this.calendarItems.forEach(item => {
        this.events.push(this.createEventHandleFromCalendarItem(item));
      });
      setTimeout(() => {
        this.calendarOptions = {
          headerToolbar: {
            left: 'prev today next',
            center: 'title',
            right: 'dayGridMonth timeGridWeek timeGridDay listWeek'
          },
          customButtons: {
            prev: {
              // text: 'prev',
              click: () => {
                this.loadPreviousMonthData();
              },
            },
            today: {
              text: 'today',
              click: () => {
                this.loadTodayData();
              },
            },
            next: {
              // text: 'next',
              click: () => {
                this.loadNextMonthData();
              },
            },
          },
          initialView: 'timeGridWeek',
          eventMouseEnter: (info) => {
            var el = document.getElementsByClassName('fc-event-title');
            $(el).attr('title', info.el.innerText)
          },
          eventDisplay: 'block',
          weekends: true,
          editable: true,
          selectable: true,
          selectMirror: true,
          dayMaxEvents: true,
          dateClick: this.onDayClick.bind(this),
          eventClick: this.onEventClick.bind(this),
          select: this.onSelect.bind(this),
          events: this.events,
        };
      }, 100);
    }
  } // end loadEvents()

  // Implement the isDateRangeLoaded function
  // isDateRangeLoaded(startMonth: number, startYear: number, endMonth: number, endYear: number): boolean {
  //   // Iterate through the loadedDateRanges array to check if the requested range is already loaded
  //   console.log('IS DATA RANGE LOADED VALUES: ', this.loadedDateRanges);
  //   for (const loadedRange of this.loadedDateRanges) {
  //     if (
  //       startYear === loadedRange.startYear &&
  //       startMonth === loadedRange.startMonth &&
  //       endYear === loadedRange.endYear &&
  //       endMonth === loadedRange.endMonth
  //       ) {
  //         // The requested range matches a loaded range, so it's already loaded
  //         console.log('IS DATA RANGE LOADED TRUE: ', loadedRange);
  //         return true;
  //       }
  //     }

  //     // If no match was found, the requested range is not loaded
  //   console.log('DATA RANGE LOAEDED FALSE: ', startMonth, startYear, endMonth, endYear);
  //   return false;
  // }

  loadPreviousMonthData() {
    // Get the FullCalendar instance
    const calendarApi = this.ucCalendar.getApi();

    // Use the 'prev' method to navigate to the previous month
    calendarApi.prev();

    // Get the current view
    const currentView = calendarApi.view;

    // Get the active start date of the current view
    const startDate = currentView.activeStart;
    const endDate = currentView.activeEnd;

    // Calculate the start month and year
    let newStartMonth = startDate.getMonth(); // Adjust for API indexing
    let newStartYear = startDate.getFullYear();
    let newEndMonth = startDate.getMonth(); // The new end is the current month
    let newEndYear = startDate.getFullYear();

    // Extract month and year
  const startMonth = startDate.getMonth();
  const startYear = startDate.getFullYear();
  const endMonth = endDate.getMonth();
  const endYear = endDate.getFullYear();

  const apiStartMonth = startMonth + 1;
  const apiEndMonth = endMonth + 1;
      // Now load the data for the new range
      this.loadDataForSelectedDate(apiStartMonth, startYear, apiEndMonth, endYear);    
    // }

    // If we are within the range, no need to load data
  } // end loadPreviousMonthData()

  // Utility function to update loaded date ranges
  updateLoadedDateRanges(startMonth: number, startYear: number, endMonth: number, endYear: number): void {
    this.loadedDateRanges.push({ startMonth, startYear, endMonth, endYear });
  }

  // Function to check if date range is already loaded
  isDateRangeLoaded(month: number, year: number): boolean {
    return this.loadedDateRanges.some(range =>
      (year > range.startYear || (year === range.startYear && month >= range.startMonth)) &&
      (year < range.endYear || (year === range.endYear && month <= range.endMonth))
    );
  }

  // loadPreviousMonthData() {
  //   // Get the FullCalendar instance
  //   const calendarApi = this.ucCalendar.getApi();

  //   // Use the 'prev' method to navigate to the previous month
  //   calendarApi.prev();

  //   // Get the current view
  //   const currentView = calendarApi.view;

  //   // Get the active start and end dates of the current view
  //   const startDate = currentView.activeStart;
  //   const endDate = currentView.activeEnd;

  //   // Extract the month and year from the start and end dates
  //   const startMonth = startDate.getMonth() + 1; // Adding 1 because months are zero-based
  //   const startYear = startDate.getFullYear();
  //   const endMonth = endDate.getMonth() + 1; // Adding 1 because months are zero-based
  //   const endYear = endDate.getFullYear();

  //   // Check if the requested date range is already loaded
  //   if (!this.isDateRangeLoaded(startMonth, startYear, endMonth, endYear)) {
  //     // If not loaded, update the loadedDateRanges array with the new date range
  //     this.loadedDateRanges.push({ startMonth, startYear, endMonth, endYear });

  //     // Now, you have the start and end dates, as well as their corresponding months and years
  //     // Call your data loading function here
  //     this.loadDataForSelectedDate(startMonth, startYear, endMonth, endYear);
  //   }
  // }

  // loadTodayData() {
  //   // Get the FullCalendar instance
  //   const calendarApi = this.ucCalendar.getApi();

  //   // Use the 'today' method to navigate to the current date
  //   calendarApi.today();
  // // Get the current view
  // const currentView = calendarApi.view;

  // // Get the active start date of the current view
  // const startDate = currentView.activeStart;

  // // Calculate the start month and year
  // const newStartMonth = startDate.getMonth(); // New current month after navigating
  // const newStartYear = startDate.getFullYear();

  // // Check if the new month/year is outside of the loaded range
  // if (newStartYear < this.loadedStartYear || (newStartYear === this.loadedStartYear && newStartMonth < this.loadedStartMonth)) {
  //   // The navigated month is before the loaded range, so we need to load more data

  //   // Calculate the new range to load
  //   this.loadedEndMonth = this.loadedStartMonth; // The new end is the old start
  //   this.loadedEndYear = this.loadedStartYear;

  //   // Subtract 3 months to find the new start
  //   this.loadedStartMonth = newStartMonth - 3;
  //   this.loadedStartYear = newStartYear;

  //   // Adjust if we have moved into the previous year
  //   if (this.loadedStartMonth < 0) {
  //     this.loadedStartMonth += 12; // Wrap to the previous year
  //     this.loadedStartYear -= 1;
  //   }

  //   // Now load the data for the new range
  //   // this.loadDataForSelectedDate(this.loadedStartMonth, this.loadedStartYear, this.loadedEndMonth, this.loadedEndYear);
  // }
  // } // end loadTodayData()

  loadTodayData() {
    // Get the FullCalendar instance
    const calendarApi = this.ucCalendar.getApi();
    // Use the 'today' method to navigate to the current date
    calendarApi.today();
    // Get the current view
    const currentView = calendarApi.view;
    const startDate = currentView.activeStart;
    const endDate = currentView.activeEnd;

    const startMonth = startDate.getMonth();
    const startYear = startDate.getFullYear();
    const endMonth = endDate.getMonth();
    const endYear = endDate.getFullYear();

    // Convert to 1-indexed months if your API expects that
    const apiStartMonth = startMonth + 1;
    const apiEndMonth = endMonth + 1;

    this.loadDataForSelectedDate(apiStartMonth, startYear, apiEndMonth, endYear);
  }

  loadNextMonthData() {
    const calendarApi = this.ucCalendar.getApi();
    calendarApi.next();

    const currentView = calendarApi.view;
    const startDate = currentView.activeStart;
    const endDate = new Date(currentView.activeEnd);
    endDate.setDate(endDate.getDate() - 1); // Adjust to get the last day of the visible week

    const startMonth = startDate.getMonth();
    const startYear = startDate.getFullYear();
    const endMonth = endDate.getMonth();
    const endYear = endDate.getFullYear();

    // Convert to 1-indexed months if your API expects that
    const apiStartMonth = startMonth + 1;
    const apiEndMonth = endMonth + 1;

    this.loadDataForSelectedDate(apiStartMonth, startYear, apiEndMonth, endYear);
}

  loadDataForSelectedDate(startMonth: number, startYear: number, endMonth: number, endYear: number) {
    const snack: Snack = { label: 'Loading Calendar...', action: null };
    this.snackbarsService.snackbarComponent.make(snack, 5000).show();
    this.activeCalendarItem = Object.assign({}, DEFAULT_CALENDAR_ITEM);
    snack.label = 'Loading fees...';
    this.snackbarsService.snackbarComponent.make(snack, 5000).show();
    let timeZoneId = moment().toDate().toString().split('(')[1].substring(0, moment().toDate().toString().split('(')[1].length - 1);
    this.loadingService.showOverlay();
    this.fromCalendarItemsService.getFromCalendarItemsByDate(startMonth, startYear, endMonth, endYear, timeZoneId).subscribe({
      next: (calendarItems) => {
        this.calendarItems = [];
        if (this.calendarItems) {
          calendarItems.forEach(item => {
            this.calendarItems.push(item);
          });
          let isOutlookTokenError = calendarItems.filter(x => x.isOutlookCalendarError == true);
          this.isOutlookTokenError = isOutlookTokenError.length > 0 ? true : false;
          calendarItems.forEach(item => {
            if (!item.isOutlookCalendarError) {
              const existingItem = this.calendarItems.find(existing => existing.OutlookCalendarEventId === item.OutlookCalendarEventId);
              if (!existingItem) {
                this.calendarItems.splice(item);
              }
            }
          });
        }
        //   calendarItems.forEach(item => {
        //     if (!item.isOutlookCalendarError)
        //       this.calendarItems.push(item);
        //   });
        // } 
        else {
          this.calendarItems = calendarItems;
        }
        this.loadingService.hideOverlay();
      },
      error: (error) => {
        const msg = 'Error loading Calendar Items.';
        console.error(msg, error);
        snack.label = msg;
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack, 5000).show();
      },
      complete: () => { this.loadEvents(); }
    });

    this.mattersService.getMatters().subscribe({
      next:
        (matters) => {
          this.matters = matters;
        },
      error: (error) => {
        const msg = 'Error loading matters.';
        console.error(msg, error);
        snack.label = msg;
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack, 5000).show();
      },
      complete: () => { /* On Complete */ }
    });

    this.mattersService.getSentMatters().subscribe({
      next:
        (sentMatters) => {
          this.sentMatters = sentMatters;
        },
      error: (error) => {
        const msg = 'Error loading sent matters.';
        console.error(msg, error);
        snack.label = msg;
        snack.type = SnackType.ERROR;
        this.snackbarsService.dismiss().make(snack, 5000).show();
      },
      complete: () => { }
    });
  }

  onEventRender(details) {
    // console.log('EVENT RENDER: ', details)
    if (details && details.event) {
      const event: EventHandle = details.event;
      if (event.data) {
        // Set Calendar item
        const calendarItem: FromCalendarItem = event.data;
        // const tooltip = calendarItem.Subject;
        // $(details.element[0]).attr('title', tooltip);
      }
    }
  }

  onViewRender(details) {
    // console.log('ON VIEW RENDER: ', details);
    this.view = details.view;
  } // end onViewRender()

  onDayClick(details) {
    if (!this.showAddFeeCalendarModal) {
      // console.log('CALENDAR CLICKED: ', details);
      this.activeCalendarItem = Object.assign({}, DEFAULT_CALENDAR_ITEM);
      //this.activeCalendarItem.Date = details.date.format();
      this.activeCalendarItem.Date = new Date().toISOString();
      this.activeCalendarItem.FromDate = this.activeCalendarItem.Date;
      this.activeCalendarItem.ToDate = this.activeCalendarItem.Date;
      this.selectedStartTime = moment(new Date()).format('HH:mm:ss');
      this.selectedEndTime = moment().add(30, 'minutes').format('HH:mm:ss');
      this.activeCalendarItem.RateType = details.date._ambigTime
        ? 'Once Off'
        : 'Daily';

      this.onShowCalenderItemModal();
    }
  } // end onDayClick()

  onEventClick(details) {
    // console.log('EVENT CLICK: ', details.event._def.extendedProps.data)
    if (details && details.event) {
      this.activeEvent = details.event._def.extendedProps;
      if (this.activeEvent.data) {
        // Set Calendar item
        this.activeCalendarItem = this.activeEvent.data;
        this.selectedMatter = this.matters.find(matter => {
          return matter.ServiceID === this.activeCalendarItem.ServiceID;
        });
        if (!this.selectedMatter) {
          this.selectedMatter = this.sentMatters.find(sentMatter => {
            return sentMatter.ServiceID === this.activeCalendarItem.ServiceID;
          });
        }
        this.selectedSubCategory = this.subCategories.find(subCategory => {
          return subCategory.SubCategoryId === this.activeCalendarItem.SubCategoryID;
        });

        if (!this.selectedSubCategory) {
          this.selectedSubCategory = Object.assign({}, DEFAULT_SUB_CATEGORY);
        }
      }

      // Time
      const toDate = this.activeEvent.data && this.activeEvent.data.ToDate
        ? this.activeEvent.data.ToDate
        : new Date().toDateString().replace(/.[\d]+Z/, '');

      const fromDate = this.activeEvent.data && this.activeEvent.data.FromDate
        ? this.activeEvent.data.FromDate
        : new Date().toDateString().replace(/.[\d]+Z/, '');
      const end = toDate.substr(toDate.indexOf('T') + 1);
      const from = fromDate.substr(fromDate.indexOf('T') + 1);

      this.selectedStartTime = from;
      // this.selectedEndTime = details.event.end
      //   ? details.event.end.format('HH:mm')
      //   : end;
      this.selectedEndTime = end;
      this.outlookEventId = this.activeEvent.data.OutlookCalendarEventId;
      this.isOutlookCalendarEntry = this.activeEvent.data.isAuxconCalendarEntry || this.activeEvent.data.isAuxconCalendarEntry == undefined ? false : this.activeEvent.data.isAuxconCalendarEntry == false ? true : false;
      this.util.setOutlookCalendarEntryData(this.isOutlookCalendarEntry);
    }
    if (this.activeCalendarItem.CanEdit)
      this.onShowCalenderItemModal();
  } // end onEventClick()

  onSelect(details) {
    debugger;
    if (!this.showAddFeeCalendarModal) {
      this.activeCalendarItem = Object.assign({}, DEFAULT_CALENDAR_ITEM);
      this.activeCalendarItem.RateType = 'Hourly';
      this.activeCalendarItem.Date = details.start.toISOString();
      this.activeCalendarItem.FromDate = this.activeCalendarItem.Date;
      this.activeCalendarItem.ToDate = details.end.toISOString();
      this.selectedStartTime = moment(details.start).format('HH:mm:ss');
      this.selectedEndTime = moment(details.end).format('HH:mm:ss');
      this.isOutlookCalendarEntry = false;
      this.onShowCalenderItemModal();
    }
  } // end onSelect()

  onButtonClick(details) {
    // console.log('ON VIEW RENDER: ', details);
    if (
      details.buttonType.indexOf('prev') < 0 &&
      details.buttonType.indexOf('next') < 0
    ) {
      this.changeTab(details.buttonType);
    }
  } // end onButtonClick()

  onShowCalenderItemModal() {
    this.showAddFeeCalendarModal = true;
  } // end onShowCalendarItemModal()

  onHideCalendarItemModal() {
    this.selectedMatter = Object.assign({}, DEFAULT_MATTER);
    this.showAddFeeCalendarModal = false;
    this.outlookEventId = null;
  } // end onHideCalendarITemModal()

  onHideDailyEntryrompt() {
    this.showHideDailyEntry = false;
  } // end onHideDailyEntryrompt()

  /**
   * Creates a new few or updates an existing fee.
   */
  saveFee(event) {
    const snack: Snack = {
      label: 'Saving Fee...',
      action: null
    };
    this.snackbarsService.snackbarComponent.make(snack, 5000).show();

    const isExistingFee =
      Boolean(this.activeCalendarItem &&
        this.activeCalendarItem.CalendarItemID);

    if (isExistingFee) {
      // If old fee, update given event

      // Log activity Login
      const currentDate = new Date();
      this.activityLog.Action = 'Update calendar fee item';
      this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
      this.activityLog.LoggedApp = 'Web Application (Advocate-calendar)';
      if (this.userProfileService.userProfile.isImpersonator) {
        this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
      } else {
        this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
      }
      this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
      this.activityLog.ActionTable = 'InvoiceLine, Actions';
      this.activityLog.JsonData = JSON.stringify(this.activeCalendarItem);
      this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (onNext) => { } });
      this.fromCalendarItemsService
        .updateFromCalendarItem(this.activeCalendarItem)
        .subscribe({
          next: calendarItem => {
            // The Id for the updated item
            const updatedItemID = this.activeCalendarItem.CalendarItemID;

            // Find the item in the array
            const itemToUpdate = this.calendarItems.find(item => item.CalendarItemID === updatedItemID);
            if (itemToUpdate) {
              Object.assign(itemToUpdate, calendarItem);
            }

            // this.loadEventDetails();
          },
          error: (error) => {    
            const msg = 'Error saving fee.';
            console.error(msg, error);
            snack.label = msg;
            snack.type = SnackType.ERROR;
            this.snackbarsService.dismiss().make(snack, 5000).show();
          },
          complete: () => {
            this.showAddFeeCalendarModal = false;
            const msg = 'Fee saved.';
            snack.label = msg;
            this.snackbarsService.dismiss().make(snack, 5000).show();
            this.loadEvents();
          }
        });
    } else {
      // If new fee, create new event
      // console.log('DATA WE PASSING FROM CALENDAR : ', this.activeCalendarItem, event.RateType);
      // this.activeCalendarItem.RateType = event.RateType;

      // Log activity Login
      const currentDate = new Date();
      this.activityLog.Action = 'Create new calendar fee Item';
      this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
      this.activityLog.LoggedApp = 'Web Application (Advocate-calendar)';
      if (this.userProfileService.userProfile.isImpersonator) {
        this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
      } else {
        this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
        this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
      }
      this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
      this.activityLog.ActionTable = 'InvoiceLine, Actions';
      this.activityLog.JsonData = JSON.stringify(this.activeCalendarItem);
      this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (onNext) => { } });
      this.activeCalendarItem.OutlookCalendarEventId = this.outlookEventId;
      this.fromCalendarItemsService
        .createFromCalendarItem(this.activeCalendarItem)
        .subscribe({
          next:
            calendarItem => {
              if (this.activeCalendarItem && this.activeCalendarItem.OutlookCalendarEventId) {
                const existingItem = this.calendarItems.find(existing => existing.OutlookCalendarEventId === this.activeCalendarItem.OutlookCalendarEventId);
                if (existingItem) {
                  this.calendarItems = this.calendarItems.filter(item => item.OutlookCalendarEventId !== this.activeCalendarItem.OutlookCalendarEventId);
                }
              }
              this.calendarItems.push(calendarItem);
              this.mattersService.getMatters().subscribe({
                next: (matters) => {
                  this.matters = matters;
                },
                error: (error) => {
                },
                complete: () => { }
              });
            },
          error: (error) => {
            console.error('Error returned', error);
            if(error === 'Multiple Daily Rate') {
              this.showHideDailyEntry = true;
            }
            const msg = 'Error saving fee.';
            console.error(msg, error);
            snack.label = msg;
            snack.type = SnackType.ERROR;
            this.snackbarsService.dismiss().make(snack, 5000).show();
          },
          complete: () => {
            // this.showAddFeeCalendarModal = false;
            const msg = 'Fee saved.';
            snack.label = msg;
            this.snackbarsService.dismiss().make(snack, 5000).show();
            this.loadEvents();
          }
        });
    }
  } // end saveFee()

  /**
   * Deletes a fee from the calendar and it's respective matter.
   */
  deleteFee() {
    const snack: Snack = {
      label: 'Deleting Fee...',
      action: null
    };
    this.snackbarsService.snackbarComponent.make(snack, 5000).show();

    // Log activity Login
    const currentDate = new Date();
    this.activityLog.Action = 'Delete calendar fee item';
    this.activityLog.ActionTimeStamp = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');
    this.activityLog.LoggedApp = 'Web Application (Advocate-calendar)';
    if (this.userProfileService.userProfile.isImpersonator) {
      this.activityLog.LoggedForUserId = this.userProfileService.selectedUserProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.selectedUserProfile.personalDetails.fullName;
    } else {
      this.activityLog.LoggedForUserId = this.userProfileService.userProfile.serviceProviderID;
      this.activityLog.LoggedForUserName = this.userProfileService.userProfile.personalDetails.fullName;
    }
    this.activityLog.LoggedUserId = this.userProfileService.userProfile.serviceProviderID;
    this.activityLog.LoggedUserName = this.userProfileService.userProfile.personalDetails.fullName;
    this.activityLog.ActionTable = 'InvoiceLine, Actions';
    this.activityLog.JsonData = JSON.stringify(this.activeCalendarItem);
    this.activityLogService.addActivityLog(this.activityLog).subscribe({ next: (onNext) => { } });

    let timeZoneId = moment().toDate().toString().split('(')[1].substring(0, moment().toDate().toString().split('(')[1].length - 1);

    let ServiceProviderID = this.userProfileService.userProfile.profileType === this.profileTypes.PRACTICE_MANAGER ? +this.userProfileService.selectedUserProfile.serviceProviderID : +this.userProfileService.userProfile.serviceProviderID;
    this.fromCalendarItemsService
      .deleteFromCalendarItem(this.activeCalendarItem.CalendarItemID, timeZoneId, ServiceProviderID)
      .subscribe({
        next:
          (res) => {
            let item: any = this.calendarItems.find(items => items.CalendarItemID == res);
            if (item)
              this.calendarItems.slice(item);

            if (res.OutLookEvent.OutlookCalendarEventId != null)
              this.calendarItems.push(res.OutLookEvent);

            this.calendarItems = this.calendarItems.filter(item => {
              return item.CalendarItemID !== this.activeCalendarItem.CalendarItemID;
            });
          },
        error: (error) => {
          const msg = 'ERROR: Failed to delete fee.';
          console.error(msg, error);
          snack.label = msg;
          snack.type = SnackType.ERROR;
          this.snackbarsService.dismiss().make(snack, 5000).show();
        },
        complete: () => {
          // On Complete
          snack.label = 'Fee deleted.';
          this.snackbarsService.dismiss().make(snack, 5000).show();

          setTimeout(() => { this.loadEvents(); }, 200);


        }
      });
  } // end deleteFee()

  ConnectOutlookCalendar() {
    const width = 500, height = 600;
    const left = (window.innerWidth - width) / 2, top = (window.innerHeight - height) / 2;
    this.openedWindow = window.open(
      'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?'
      + 'client_id=' + environment.office365ClientId
      + '&scope=email%20profile%20openid%20offline_access%20User%2eReadWrite%20Calendars%2eReadWrite%20Calendars%2eReadWrite%2eShared'
      + '&redirect_uri=' + environment.outlookCalendarRedirectURL
      + '&response_type=code'
      + '&response_mode=query'
      + '&prompt=select_account', '_blank', `width=${width}, height=${height}, left=${left}, top=${top}`);
  }

  onHideThisEvent(event) {
    if (event.OutlookCalendarEventId) {
      const snack: Snack = {
        label: 'Outlook Event Deleting...',
        action: null
      };
      this.snackbarsService.snackbarComponent.make(snack, 5000).show();
      this.loadingService.showOverlay();
      this.fromCalendarItemsService.deleteOutlookEvent(event.OutlookCalendarEventId).subscribe({
        next: (next) => {
          this.calendarItems = this.calendarItems.filter(item => item.OutlookCalendarEventId !== event.OutlookCalendarEventId);
        },
        error: (error) => {
          const msg = 'ERROR: Failed to outlook delete event.';
          console.error(msg, error);
          snack.label = msg;
          snack.type = SnackType.ERROR;
          this.snackbarsService.dismiss().make(snack, 5000).show();
        },
        complete: () => {
          snack.label = 'Outlook Event Deleted.';
          this.snackbarsService.dismiss().make(snack, 5000).show();
          this.loadEvents();
          this.loadingService.hideOverlay();
        }
      });
    }
  }

  ngAfterViewInit() {
    const calendarApi = this.ucCalendar.getApi();
    const currentView = calendarApi.view;
    const startDate = currentView.activeStart;
    const endDate = new Date(currentView.activeEnd);
    endDate.setDate(endDate.getDate() - 1); // Adjust to get the last visible day

    const startMonth = startDate.getMonth();
    const startYear = startDate.getFullYear();
    const endMonth = endDate.getMonth();
    const endYear = endDate.getFullYear();

    // Convert to 1-indexed months for API call if necessary
    const apiStartMonth = startMonth + 1;
    const apiEndMonth = endMonth + 1;

    this.loadDataForSelectedDate(apiStartMonth, startYear, apiEndMonth, endYear);
} // end ngAfterViewInit()

  loadEventDetails() {
    const snack: Snack = {
      label: 'Loading Fee...',
      action: null
    };
    this.snackbarsService.snackbarComponent.make(snack, 5000).show();
    if (this.loadedStartMonth > 0) {
      this.calendarItems = [];
      this.loadDataForSelectedDate(this.loadedStartMonth, this.loadedStartYear, this.loadedEndMonth, this.loadedEndYear);
    }
  }

} // end AdvocateCalendarComponent{}