<div class="container-fluid">
  <h2 class="page-title">{{title}}</h2>
  <div class="text-right mr-5 mt-4 mb-3">
    <span class="outlookErrorMessage" *ngIf="isOutlookTokenError" style="font-size: 12px;">
      <i class="fa fa-exclamation-triangle"></i> We can't retrive the outlook calendar event's please sync your calendar
      again. please <a [routerLink]="['/outlook-sync-setup']">click here</a> to sync your calendar
    </span>
    <span class="OutlookText" *ngIf="!isOutlookCalendarIntegrate" (click)="ConnectOutlookCalendar()">
      <a [routerLink]="['/outlook-sync-setup']" [queryParams]="{Code: 'CalendarSetup'}">
        <img src="../../../assets/images/icons/outlook_icon.png" width="15" />
        Sync Outlook Calendar
      </a>
    </span>
    <span *ngIf="isOutlookCalendarIntegrate && !isOutlookTokenError" style="font-size: 15px;">
      <span class="Unallocated mr-1">
        <img src="../../../assets/images/icons/outlook_icon.png" height="18" />
      </span>&nbsp;Unallocated
      <span class="mr-4"></span>
      <span class="Allocated ml-2 mr-1"></span>&nbsp;Allocated to Matter
    </span>
  </div>
  <div class="calendar-container" *ngIf="calendarOptions">
    <full-calendar #ucCalendar [options]="calendarOptions" (viewRender)="onViewRender($event.detail)"
      (eventClick)="onEventClick($event.detail)" (clickButton)="onButtonClick($event.detail)"
      (eventRender)="onEventRender($event.detail)" [(eventsModel)]="events" #CalenderE1></full-calendar>
  </div>
</div>
<app-add-fee-calendar-modal *ngIf="showAddFeeCalendarModal" [isVisible]="showAddFeeCalendarModal" [outlookEventId]="outlookEventId"
  [isOutlookCalendarEntry]="isOutlookCalendarEntry" [matters]="matters" [defaultMatter]="selectedMatter"
  [subCategories]="subCategories" [defaultSubCategory]="selectedSubCategory" [(calendarItem)]="activeCalendarItem"
  [(startTime)]="selectedStartTime" [(endTime)]="selectedEndTime" (cancel)="onHideCalendarItemModal()"
  (save)="saveFee($event)" (delete)="deleteFee()"
  (onHideThisEvent)="onHideThisEvent($event)"></app-add-fee-calendar-modal>

<app-prompt-unsaved-changes-modal title="Duplicate Daily Entry Detected"
  message="You already have a Daily Rate entry for this day. The system doesn't allow multiple Daily Rate entries on the same day. Please review your entries and try again."
  btnTextCancel="Cancel"
  btnTextContinue="Ok"
  [isVisible]="showHideDailyEntry"
  (cancel)="onHideDailyEntryrompt()"
  (continue)="onHideDailyEntryrompt()"></app-prompt-unsaved-changes-modal>