import { Component, OnInit } from '@angular/core';
import {
  Contacts,
  ContactsHandle,
  ContactsService,
  DEFAULT_CONTACTS } from '../../../services/contacts/contacts.service';
import { LoadingService } from '../../../services/messaging/loading/loading.service';

@Component({
  selector: 'app-secretary',
  templateUrl: './secretary.component.html',
  styleUrls: ['./secretary.component.scss']
})
export class SecretaryComponent implements OnInit {

  //#region Secretary
  row = 0;
  sortBy: string;
  previousSortBy: string;
  sortAscending: boolean;
  sortSecretaryName: boolean;
  sortSecretaryPhone: boolean;
  sortSecretaryFax: boolean;
  sortSecretaryEmail: boolean;
  sortSecretaryVat: boolean;
  sortSecretaryPostalAddress: boolean;
  sortSecretaryStreetAddress: boolean;
  secretarySearchTerm: string;
  secretary: Contacts[] = [];
  secretaryHandle: ContactsHandle[] = [];
  selectedSecretary: Contacts = DEFAULT_CONTACTS;
  showAddNewSecretary: boolean;
  //#endregion Secretary

  //#region Advocates
  message = 'Are you sure you want to remove the Advocate?';
  showRemoveAdvocatePrompt: boolean;
  showAssignAdvocate: boolean;
  advocateSearchTerm: string;
  sortAdvocateName: boolean;
  sortAdvocatePhone: boolean;
  sortAdvocateCell: boolean;
  sortAdvocateEmail: boolean;
  advocates: Contacts[] = [];
  advocateList: Contacts[] = [];
  advocatesHandle: ContactsHandle[] = [];
  advocateToDelete: Contacts = DEFAULT_CONTACTS;
  //#endregion Advocates

  get canAssignAdvocate(): boolean {
    return Boolean(this.row > 0);
  } // end canAssignAdvocate()
  constructor(
    private loadingService: LoadingService,
    private contactService: ContactsService
  ) { } // end consructor()

  ngOnInit(): void {
    this.getAllSecretaries();
  } // end ngOnInit()

  getAllSecretaries() {
    this.secretary = [];
    this.secretaryHandle = [];
    
    this.loadingService.showOverlay();
    this.contactService.getAllContactsByContactType(4).subscribe({ next: (data) => {
      // On next
      this.secretary = data.result;
      if (this.secretary) {
        this.secretary.forEach(firm => {
          this.secretaryHandle.push({
            data: firm,
            state: {
              isVisible: true,
              isSelected: false,
              isOpen: false
            }
          });
        });
      }      
    },
    complete: () => {
      // On complete
      this.loadingService.hideOverlay();
    }
  })
  } // end getAllSecretaries()

  performSecretarySearch() {
    if (this.secretarySearchTerm) {
      this.secretaryHandle.forEach(firm => {
        if (
          !(firm.data.FullName.match(new RegExp(this.secretarySearchTerm, 'gi')))
        ) {
          firm.state.isVisible = false;
        } else {
          firm.state.isVisible = true;
        }
      });
    } else {
      this.secretaryHandle.forEach(firm => {
        firm.state.isVisible = true;
      });
    }
  } // end performSecretarySearch()

  clearSecretarySearch() {
    this.secretarySearchTerm = '';
    this.performSecretarySearch();
  } // end clearSecretarySearch()

  toggleSortSecretary(sortBy: string) {
    this.sortBy = sortBy;
    if (this.sortBy === this.previousSortBy) {
      this.sortAscending = !this.sortAscending;
    }

    switch(this.sortBy) {
      case 'advocate-name':
        this.secretaryHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortSecretaryName = true;
            this.sortSecretaryPhone = false;
            this.sortSecretaryFax = false;
            this.sortSecretaryEmail = false;
            this.sortSecretaryVat = false;
            this.sortSecretaryPostalAddress = false;
            this.sortSecretaryStreetAddress = false;
            return a.data.FullName.toUpperCase().localeCompare(b.data.FullName.toUpperCase());
          }
          this.sortSecretaryName = false;
          return b.data.FullName.toUpperCase().localeCompare(a.data.FullName.toUpperCase());
        });
      break;

      case 'cell':
        this.secretaryHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortSecretaryName = false;
            this.sortSecretaryPhone = true;
            this.sortSecretaryFax = false;
            this.sortSecretaryEmail = false;
            this.sortSecretaryVat = false;
            this.sortSecretaryPostalAddress = false;
            this.sortSecretaryStreetAddress = false;
            return a.data.Cell.toUpperCase().localeCompare(b.data.Cell.toUpperCase());
          }
          this.sortSecretaryPhone = false;
          return b.data.Cell.toUpperCase().localeCompare(a.data.Cell.toUpperCase());
        });
      break;
        
      case 'fax':
        this.secretaryHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortSecretaryName = false;
            this.sortSecretaryPhone = false;
            this.sortSecretaryFax = true;
            this.sortSecretaryEmail = false;
            this.sortSecretaryVat = false;
            this.sortSecretaryPostalAddress = false;
            this.sortSecretaryStreetAddress = false;
            return a.data.Fax.toUpperCase().localeCompare(b.data.Fax.toUpperCase());
          }
          this.sortSecretaryFax = false;
          return b.data.Fax.toUpperCase().localeCompare(a.data.Fax.toUpperCase());
        });
      break;

      case 'email':
        this.secretaryHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortSecretaryName = false;
            this.sortSecretaryPhone = false;
            this.sortSecretaryFax = false;
            this.sortSecretaryEmail = true;
            this.sortSecretaryVat = false;
            this.sortSecretaryPostalAddress = false;
            this.sortSecretaryStreetAddress = false;
            return a.data.Fax.toUpperCase().localeCompare(b.data.Fax.toUpperCase());
          }
          this.sortSecretaryEmail = false;
          return b.data.Fax.toUpperCase().localeCompare(a.data.Fax.toUpperCase());
        });
      break;

      case 'vat':
        this.secretaryHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortSecretaryName = false;
            this.sortSecretaryPhone = false;
            this.sortSecretaryFax = false;
            this.sortSecretaryEmail = false;
            this.sortSecretaryVat = true;
            this.sortSecretaryPostalAddress = false;
            this.sortSecretaryStreetAddress = false;
            return a.data.Vatno.toUpperCase().localeCompare(b.data.Vatno.toUpperCase());
          }
          this.sortSecretaryVat = false;
          return b.data.Vatno.toUpperCase().localeCompare(a.data.Vatno.toUpperCase());
        });
      break;

      case 'postal-address':
        this.secretaryHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortSecretaryName = false;
            this.sortSecretaryPhone = false;
            this.sortSecretaryFax = false;
            this.sortSecretaryEmail = false;
            this.sortSecretaryVat = false;
            this.sortSecretaryPostalAddress = true;
            this.sortSecretaryStreetAddress = false;
            return a.data.PostalAddress.toUpperCase().localeCompare(b.data.PostalAddress.toUpperCase());
          }
          this.sortSecretaryPostalAddress = false;
          return b.data.PostalAddress.toUpperCase().localeCompare(a.data.PostalAddress.toUpperCase());
        });
      break;

      case 'street-address':
        this.secretaryHandle.sort((a, b) => {
          if (this.sortAscending) {
            this.sortSecretaryName = false;
            this.sortSecretaryPhone = false;
            this.sortSecretaryFax = false;
            this.sortSecretaryEmail = false;
            this.sortSecretaryVat = false;
            this.sortSecretaryPostalAddress = false;
            this.sortSecretaryStreetAddress = true;
            return a.data.StreetAddress.toUpperCase().localeCompare(b.data.StreetAddress.toUpperCase());
          }
          this.sortSecretaryStreetAddress = false;
          return b.data.StreetAddress.toUpperCase().localeCompare(a.data.StreetAddress.toUpperCase());
        });
      break;
    }
    this.previousSortBy = this.sortBy;
  } // end toggleSortSecretary()

  performAdvocateSearch() {
    if (this.advocateSearchTerm) {
      this.advocatesHandle.forEach(firm => {
        if (
          !(firm.data.FullName.match(new RegExp(this.advocateSearchTerm, 'gi')))
        ) {
          firm.state.isVisible = false;
        } else {
          firm.state.isVisible = true;
        }
      });
    } else {
      this.advocatesHandle.forEach(firm => {
        firm.state.isVisible = true;
      });
    }
  } // end performAdvocateSearch()

  clearAdvocateSearch() {
    this.advocateSearchTerm = '';
    this.performAdvocateSearch();
  } // end clearAdvocateSearch()

  toggleSortAdvocate(sortBy: string) {

  } // end toggleSortAdvocate()

  onSecretaryRowClick(data: Contacts) {
    this.loadingService.showOverlay();
    this.advocates = [];
    this.advocatesHandle = [];
    this.selectedSecretary = DEFAULT_CONTACTS;
    this.row = 0;
    if (data) {
      this.row = data.Id;
      this.selectedSecretary = data;
      this.contactService.getAdvocateBySecretary(data.Id).subscribe({ next: (advocates) => {
        // On next
        this.advocates = advocates;
        if (this.advocates) {
          this.advocates.forEach(_advocate => {
            this.advocatesHandle.push({
              data: _advocate,
              state: {
                isVisible: true,
                isSelected: false,
                isOpen: false
              }
            });
          });
        }
        this.loadingService.hideOverlay();
      }});
    }
  } // end onSecretaryRowClick()

  onEditSecretary(secretary: Contacts) {
    this.selectedSecretary = {
      AccountHolder: '',
      AccountNumber: '',
      AccountType: '',
      BranchCode: '',
      Cell: '',
      CollectThreshold: '',
      ContactContactType: 0,
      Locality: '',
      ContactLocality: 0,
      DefaultDailyRate: 0,
      DefaultHourlyRate: 0,
      DefaultPerKilometerRate: 0,
      Docex: '',
      Email: '',
      Fax: '',
      FeeTarget: 0,
      FullName : '',
      Id: 0,
      Identity: '',
      Image: '',
      IncomeTarget: 0,
      LastStatementDownload: '',
      Level: '',
      OfficeRoom: '',
      Phone: '',
      PostalAddress: '',
      SecurityLinks: '',
      Speciality: '',
      Status: '',
      StreetAddress: '',
      Suburb: '',
      ToCollect: 0,
      UserName: '',
      Vatno: '',
      WebSite: '',
      ZipCode: '',
      IsSelected: false,
      AdmissionDay : null,
      AdmissionMonth : null,
      AdmissionYear : null,
      AdmissionDate : null,
      AdmissionDateStr : '',
      RegionalBarId : 0,
      RegionalBarName : '',
      ChamberID: 0,
      TenantID: '',
      Domain: '',
      ContactTypeID: 2,
      Ethincity: 'African',
      Gender: 'Male',
      Chamber: ''
    };
    this.selectedSecretary = secretary;
    this.showAddNewSecretary = true;
  } // end onEditSecretary()

  onEditAdvocate() {

  } // end onEditAdvocate()

  assignNewAdvocate() {
    this.loadingService.showOverlay();
    this.contactService.getAllContactsByContactType(2).subscribe({ next: (data) => {
      // On next
      this.advocateList = data.result;
    },
    error: (error) => {
      // On error
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      this.showAssignAdvocate = true;
      this.loadingService.hideOverlay();
    }
  })
  } // end assignNewAdvocate()

  onCloseModal(event) {
    switch(event) {
      case 'assign-advocate':
        this.showAssignAdvocate = false;
      break;

      case 'secretary':
        this.showAddNewSecretary = false;
      break;
    }
  } // end onCloseModal()

  onAssignedAdvocate(event) {
    this.onSecretaryRowClick(this.selectedSecretary);
    // this.showAssignAdvocate = false;
  } // end onAssignedAdvocate()

  addNewContact() {
    this.selectedSecretary = {
      AccountHolder: '',
      AccountNumber: '',
      AccountType: '',
      BranchCode: '',
      Cell: '',
      CollectThreshold: '',
      ContactContactType: 0,
      Locality: '',
      ContactLocality: 0,
      DefaultDailyRate: 0,
      DefaultHourlyRate: 0,
      DefaultPerKilometerRate: 0,
      Docex: '',
      Email: '',
      Fax: '',
      FeeTarget: 0,
      FullName : '',
      Id: 0,
      Identity: '',
      Image: '',
      IncomeTarget: 0,
      LastStatementDownload: '',
      Level: '',
      OfficeRoom: '',
      Phone: '',
      PostalAddress: '',
      SecurityLinks: '',
      Speciality: '',
      Status: '',
      StreetAddress: '',
      Suburb: '',
      ToCollect: 0,
      UserName: '',
      Vatno: '',
      WebSite: '',
      ZipCode: '',
      IsSelected: false,
      AdmissionDay : null,
      AdmissionMonth : null,
      AdmissionYear : null,
      AdmissionDate : null,
      AdmissionDateStr : '',
      RegionalBarId : 0,
      RegionalBarName : '',
      ChamberID: 0,
      TenantID: '',
      Domain: '',
      ContactTypeID: 2,
      Ethincity: 'African',
      Gender: 'Male',
      Chamber: ''
    };
    this.showAddNewSecretary = true;
  } // end addNewContact()

  onSaveSecretary(event) {
    this.getAllSecretaries();
    this.showAddNewSecretary = false;
  } // end onSaveSecretary()

  onRemoveAdvocate(contact: Contacts) {
    this.message = 'Are you sure you want to remove ' + contact.FullName + ' from ' + this.selectedSecretary.FullName + '?';
    this.advocateToDelete = {
      AccountHolder: '',
      AccountNumber: '',
      AccountType: '',
      BranchCode: '',
      Cell: '',
      CollectThreshold: '',
      ContactContactType: 0,
      Locality: '',
      ContactLocality: 0,
      DefaultDailyRate: 0,
      DefaultHourlyRate: 0,
      DefaultPerKilometerRate: 0,
      Docex: '',
      Email: '',
      Fax: '',
      FeeTarget: 0,
      FullName : '',
      Id: 0,
      Identity: '',
      Image: '',
      IncomeTarget: 0,
      LastStatementDownload: '',
      Level: '',
      OfficeRoom: '',
      Phone: '',
      PostalAddress: '',
      SecurityLinks: '',
      Speciality: '',
      Status: '',
      StreetAddress: '',
      Suburb: '',
      ToCollect: 0,
      UserName: '',
      Vatno: '',
      WebSite: '',
      ZipCode: '',
      IsSelected: false,
      AdmissionDay : null,
      AdmissionMonth : null,
      AdmissionYear : null,
      AdmissionDate : null,
      AdmissionDateStr : '',
      RegionalBarId : 0,
      RegionalBarName : '',
      ChamberID: 0,
      TenantID: '',
      Domain: '',
      ContactTypeID: 2,
      Ethincity: 'African',
      Gender: 'Male',
      Chamber: ''
    };
    this.advocateToDelete = contact;
    this.showRemoveAdvocatePrompt = true;
  } // end onRemoveAdvocate()

  onHideRemoveAdvocatePrompt() {
    this.showRemoveAdvocatePrompt = false;
  } // end onHideRemoveAdvocatePrompt()

  onRemoveAdvocateFromSecretary() {
    this.loadingService.showOverlay();
    this.contactService.deleteAdvocateToAssistant(this.selectedSecretary.Id, this.advocateToDelete.Id).subscribe({ next: (data) => {
      // On next
    },
    error: (error) => {
      // On error
      this.loadingService.hideOverlay();
    },
    complete: () => {
      // On complete
      this.onSecretaryRowClick(this.selectedSecretary);
      this.loadingService.hideOverlay();
      this.showRemoveAdvocatePrompt = false;
    }
  })
  } // end onRemoveAdvocateFromSecretary()
} // end SecretaryComponent {}
